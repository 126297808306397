<template>
  <v-dialog
    persistent
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
    width="850"
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              <LuDocumentType
                label="Document Type"
                v-model="selectedApplicationDocumentType.DocumentTypeId"
                :rules="[(v) => !!v || 'required']"
              ></LuDocumentType>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-checkbox
                label="Is Required"
                v-model="selectedApplicationDocumentType.IsRequired"
                :rules="[(v) => v === true || v === false || 'required']"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-checkbox
                label="Is For Household Member"
                v-model="selectedApplicationDocumentType.IsForHouseholdMember"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="saveApplicationDocumentTypeDetails"
            :disabled="saving"
            color="primary"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import ApplicationDocumentType from '@classes/ApplicationDocumentType'
//templateTODO: import lookup components you plan to use
import LuDocumentType from '@components/select/LuDocumentType/LuDocumentType-single'
export default {
  components: {
    //templateTodo: define lookup components you are using
    LuDocumentType,
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedApplicationDocumentType: new ApplicationDocumentType(),
    modalText: '',
  }),
  created() {},
  computed: {
    ...get('applicationDocumentType', ['saving']),
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('applicationDocumentType', ['saveApplicationDocumentType']),
    async validate() {
      await this.$refs.form.validate()
    },
    editApplicationDocumentType(entry) {
      this.selectedApplicationDocumentType = new ApplicationDocumentType(entry)
      this.modalText = 'Edit Application Document Type'
      this.dialog = !this.dialog
    },
    addApplicationDocumentType() {
      this.modalText = 'Insert Application Document Type'
      this.selectedApplicationDocumentType = new ApplicationDocumentType()
      this.dialog = !this.dialog
    },
    async saveApplicationDocumentTypeDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        try {
          await this.saveApplicationDocumentType(
            this.selectedApplicationDocumentType.removeRelated()
          )
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedApplicationDocumentType = new ApplicationDocumentType()
          this.$refs.form.resetValidation()
        } catch (err) {}
        this.dialog = false
      }
    },
    cancelEntry() {
      this.dialog = false
      this.selectedApplicationDocumentType = new ApplicationDocumentType()
      this.$refs.form.resetValidation()
    },
  },
}
</script>
<style></style>
