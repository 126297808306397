<template>
  <BasePanelWithHeader headerText="Funding Sponsors">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="filteredLuFundingSponsors"
      :loading="loading"
      search
    >
      <template v-slot:prependParams>
        <v-switch
          v-model="showInactive"
          label="Show Inactive"
          class="py-4"
        ></v-switch>
      </template>
      <template v-slot:appendParams>
        <BaseTooltipButton
          small
          @click="$refs.luFundingSponsorForm.addLuFundingSponsor()"
          icon="mdi-plus"
          iconColor="white"
          buttonClass="primary  mt-8 ml-2"
          >Add Funding Sponsor
        </BaseTooltipButton>
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luFundingSponsorForm.editLuFundingSponsor(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Funding Sponsor
        </BaseTooltipButton>
      </template>
      <template v-slot:item.IsActive="{ item }">
        {{ item.IsActive ? 'Yes' : 'No' }}
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luFundingSponsorDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete Funding Sponsor
        </BaseTooltipButton>
      </template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="luFundingSponsorDelete"
      :delete="deleteLuFundingSponsor"
      @refresh="clearLookupCacheAndReloadLuFundingSponsors"
    >
      Are you sure you want to delete this funding sponsor?
    </BaseDeleteConfirm>
    <LuFundingSponsorDialogForm
      ref="luFundingSponsorForm"
      @refresh="clearLookupCacheAndReloadLuFundingSponsors"
    >
    </LuFundingSponsorDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuFundingSponsorDialogForm from '@components/form/add-edit/LuFundingSponsorDialog'
export default {
  components: {
    LuFundingSponsorDialogForm,
  },
  data: () => ({
    showInactive: false,
  }),
  created() {
    this.loadLuFundingSponsors()
  },
  computed: {
    ...get('luFundingSponsor', ['luFundingSponsors', 'loading']),
    filteredLuFundingSponsors() {
      var list = this.luFundingSponsors.filter((i) => true)
      if (!this.showInactive) {
        list = list.filter((i) => i.IsActive)
      }
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'left',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Description',
          align: 'left',
          sortable: true,
          value: 'Description',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'left',
          sortable: true,
          value: 'IsActive',
          visible: this.showInactive,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('luFundingSponsor', [
      'deleteLuFundingSponsor',
      'loadLuFundingSponsors',
      'clearLookupCacheAndReloadLuFundingSponsors',
    ]),
  },
}
</script>
<style></style>
