<template>
  <BasePanelWithHeader headerText="Globals">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="filteredLuGlobals"
      :searchText="searchText"
      :loading="loading"
    >
    <template v-slot:searchParams>
      <v-row>
        <v-col class="ml-auto" cols="12" sm="auto">
          <v-text-field 
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Search"
            clearable
            single-line
            hide-details>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="auto" class="mt-4 mr-4">
          <BaseTooltipButton 
            small 
            @click="$refs.luGlobalForm.addLuGlobal()" 
            icon="mdi-plus" 
            iconColor="white"
            buttonClass="primary"
            >Add Global
          </BaseTooltipButton>
        </v-col>
      </v-row>
    </template>
        <template v-slot:item.edit="{item}">
          <BaseTooltipButton
          small
          @click="$refs.luGlobalForm.editLuGlobal(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Global
          </BaseTooltipButton>
        </template>
        <template v-slot:item.delete="{item}">
          <BaseTooltipButton
            small
            @click="$refs.luGlobalDelete.deleteConfirm(item)"
            iconColor="primary"
            icon="mdi-delete"
            >Delete Global
            </BaseTooltipButton>
        </template>
      </BaseSimpleDataTable>
      <BaseDeleteConfirm
        ref="luGlobalDelete"
        :delete="deleteLuGlobal"
        @refresh="clearLookupCacheAndReloadLuGlobals">
        Are you sure you want to delete this global?
      </BaseDeleteConfirm>
      <LuGlobalDialogForm 
        ref="luGlobalForm" 
        @refresh="clearLookupCacheAndReloadLuGlobals">
      </LuGlobalDialogForm>
  </BasePanelWithHeader>
</template>
<script>
  import { get, sync, commit, call } from 'vuex-pathify'
  import LuGlobalDialogForm from '@components/form/add-edit/LuGlobalDialog'
  export default {
      components: {
        LuGlobalDialogForm,
      },
      data: () => ({
        searchText: '',
      }),
      created() {
          this.loadLuGlobals()
      },
      computed: {
        ...get('luGlobal', ['luGlobals', 'loading']),
        filteredLuGlobals () {
          var list =  this.luGlobals.filter((i) => true )
          return list
        },
        headers() {
          return [
            {
              text: '',
              align: 'center',
              value: 'edit',
              visible: true,
              sortable: false,
            },
            {
              text: 'Id',
              align: 'center',
              sortable: true,
              value: 'Id',
              visible: false,
            },
            {
              text: 'Key',
              align: 'center',
              sortable: true,
              value: 'Key',
              visible: true,
            },
            {
              text: 'Value',
              align: 'center',
              sortable: true,
              value: 'Value',
              visible: true,
            },
            {
              text: '',
              align: 'center',
              value: 'delete',
              visible: false,
              sortable: false,
            },
          ]
        },
        filteredHeaders() {
          return this.headers.filter((h) => h.visible)
        },
      },
      methods: {
        ...call('luGlobal', [
          'deleteLuGlobal', 
          'loadLuGlobals', 
          'clearLookupCacheAndReloadLuGlobals'
        ]),
      }
  } 
  
</script>
<style>

</style>