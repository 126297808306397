<template>
  <BasePanelWithHeader headerText="Funding Sources">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="filteredLuFundingSources"
      :loading="loading"
      search
    >
      <template v-slot:prependParams>
        <v-switch
          v-model="showInactive"
          label="Show Inactive"
          class="py-4"
        ></v-switch>
      </template>
      <template v-slot:appendParams>
        <BaseTooltipButton
          small
          @click="$refs.luFundingSourceForm.addLuFundingSource()"
          icon="mdi-plus"
          iconColor="white"
          buttonClass="primary mt-8 ml-2"
          >Add Funding Source
        </BaseTooltipButton>
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luFundingSourceForm.editLuFundingSource(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Funding Source
        </BaseTooltipButton>
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luFundingSourceDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete Funding Source
        </BaseTooltipButton>
      </template>
      <template v-slot:item.IsActive="{ item }">
        {{ item.IsActive ? 'Yes' : 'No' }}
      </template>
      <template v-slot:item.CreatedDate="{ item }">{{
        item.CreatedDate | formatDate
      }}</template>
      <template v-slot:item.UpdatedDate="{ item }">{{
        item.UpdatedDate | formatDate
      }}</template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="luFundingSourceDelete"
      :delete="deleteLuFundingSource"
      @refresh="clearLookupCacheAndReloadLuFundingSources"
    >
      Are you sure you want to delete this funding source?
    </BaseDeleteConfirm>
    <LuFundingSourceDialogForm
      ref="luFundingSourceForm"
      @refresh="clearLookupCacheAndReloadLuFundingSources"
    >
    </LuFundingSourceDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuFundingSourceDialogForm from '@components/form/add-edit/LuFundingSourceDialog'
export default {
  components: {
    LuFundingSourceDialogForm,
  },
  data: () => ({
    showInactive: false,
  }),
  created() {
    this.loadLuFundingSources()
  },
  computed: {
    ...get('luFundingSource', ['luFundingSources', 'loading']),
    filteredLuFundingSources() {
      var list = this.luFundingSources.filter((i) => true)
      if (!this.showInactive) {
        list = list.filter((i) => i.IsActive)
      }
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: this.showInactive,
        },
        {
          text: 'Description',
          align: 'center',
          sortable: true,
          value: 'Description',
          visible: true,
        },
        {
          text: 'Funding Source Type Id',
          align: 'center',
          sortable: true,
          value: 'FundingSourceTypeId',
          visible: false,
        },
        {
          text: 'Funding Sponsor Id',
          align: 'center',
          sortable: true,
          value: 'FundingSponsorId',
          visible: false,
        },

        {
          text: 'Created By Id',
          align: 'center',
          sortable: true,
          value: 'CreatedById',
          visible: false,
        },
        {
          text: 'Created Date',
          align: 'center',
          sortable: true,
          value: 'CreatedDate',
          visible: true,
        },
        {
          text: 'Updated By Id',
          align: 'center',
          sortable: true,
          value: 'UpdatedById',
          visible: false,
        },
        {
          text: 'Updated Date',
          align: 'center',
          sortable: true,
          value: 'UpdatedDate',
          visible: true,
        },
        {
          text: 'Agency Code',
          align: 'center',
          sortable: true,
          value: 'AgencyCode',
          visible: true,
        },
        {
          text: 'Fund Code',
          align: 'center',
          sortable: true,
          value: 'FundCode',
          visible: true,
        },
        {
          text: 'Object Code',
          align: 'center',
          sortable: true,
          value: 'ObjectCode',
          visible: true,
        },
        {
          text: 'Sub Object Code',
          align: 'center',
          sortable: true,
          value: 'SubObjectCode',
          visible: true,
        },
        {
          text: 'Unit Code',
          align: 'center',
          sortable: true,
          value: 'UnitCode',
          visible: true,
        },
        {
          text: 'Appropriation Unit',
          align: 'center',
          sortable: true,
          value: 'AppropriationUnit',
          visible: true,
        },
        {
          text: 'Activity Code',
          align: 'center',
          sortable: true,
          value: 'ActivityCode',
          visible: true,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('luFundingSource', [
      'deleteLuFundingSource',
      'loadLuFundingSources',
      'clearLookupCacheAndReloadLuFundingSources',
    ]),
  },
}
</script>
<style></style>
