<template>
  <BasePanelWithHeader headerText="Note Types">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="filteredLuNoteTypes"
      :searchText="searchText"
      :loading="loading"
    >
      <template v-slot:searchParams>
        <v-row>
          <v-col cols="12" sm="auto">
            <v-switch v-model="showInactive" label="Show Inactive"></v-switch>
          </v-col>
          <v-col class="ml-auto" cols="12" sm="auto">
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="auto" class="mt-4 mr-4">
            <BaseTooltipButton
              small
              @click="$refs.luNoteTypeForm.addLuNoteType()"
              icon="mdi-plus"
              iconColor="white"
              buttonClass="primary"
              >Add Note Type
            </BaseTooltipButton>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luNoteTypeForm.editLuNoteType(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Note Type
        </BaseTooltipButton>
      </template>
      <template v-slot:item.InternalOnly="{ item }">
        {{ item.InternalOnly ? 'Yes' : 'No' }}
      </template>
      <template v-slot:item.IsActive="{ item }">
        {{ item.IsActive ? 'Yes' : 'No' }}
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luNoteTypeDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete Note Type
        </BaseTooltipButton>
      </template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="luNoteTypeDelete"
      :delete="deleteLuNoteType"
      @refresh="clearLookupCacheAndReloadLuNoteTypes"
    >
      Are you sure you want to delete this note type?
    </BaseDeleteConfirm>
    <LuNoteTypeDialogForm
      ref="luNoteTypeForm"
      @refresh="clearLookupCacheAndReloadLuNoteTypes"
    >
    </LuNoteTypeDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuNoteTypeDialogForm from '@components/form/add-edit/LuNoteTypeDialog'
export default {
  components: {
    LuNoteTypeDialogForm,
  },
  data: () => ({
    searchText: '',
    showInactive: false,
  }),
  created() {
    this.loadLuNoteTypes()
  },
  computed: {
    ...get('luNoteType', ['luNoteTypes', 'loading']),
    filteredLuNoteTypes() {
      var list = this.luNoteTypes.filter((i) => true)
      if (!this.showInactive) {
        list = list.filter((i) => i.IsActive)
      }
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Description',
          align: 'center',
          sortable: true,
          value: 'Description',
          visible: true,
        },
        {
          text: 'Internal Only',
          align: 'center',
          sortable: true,
          value: 'InternalOnly',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: this.showInactive,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('luNoteType', [
      'deleteLuNoteType',
      'loadLuNoteTypes',
      'clearLookupCacheAndReloadLuNoteTypes',
    ]),
  },
}
</script>
<style></style>
