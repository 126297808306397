<template>
  <!--  Generated from Add_Edit_w_Lookups template-->
  <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
    <v-card class="elevation-3">
      <v-card-title class="primary text-h5 white--text">
        {{ headerText }}
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Income Percentage"
              v-model="selectedLuIncomeThreshold.IncomePercentage"
              type="number"
              :rules="[
                (v) => parseFloat(v) == parseFloat(v) || 'required',
                (v) =>
                  parseFloat(v) <= 3 ||
                  'please input percentage as a decimal (i.e. 1.25 would be 125%)',
              ]"
              hint="As a decimal (1.25 for 125%)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Refund Percentage"
              v-model="selectedLuIncomeThreshold.RefundPercentage"
              type="number"
              :rules="[
                (v) => parseFloat(v) == parseFloat(v) || 'required',
                (v) =>
                  parseFloat(v) <= 1 ||
                  'please input percentage as a decimal (i.e. .5 would be 50%)',
              ]"
              hint="As a decimal (.5 for 50%)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <BaseDatePickerWithText
              label="Effective Date mm/dd/yyyy"
              v-model="selectedLuIncomeThreshold.EffectiveDate"
              :rules="[(v) => !!v || 'required']"
            ></BaseDatePickerWithText>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <BaseDatePickerWithText
              label="Expiration Date mm/dd/yyyy"
              v-model="selectedLuIncomeThreshold.ExpirationDate"
            ></BaseDatePickerWithText>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="saveLuIncomeThresholdDetails"
          :disabled="saving"
          color="primary"
          >Save</v-btn
        >
        <v-btn v-if="inDialog" outlined color="primary" @click="cancelEntry"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuIncomeThreshold from '@classes/LuIncomeThreshold'
//templateTODO: import lookup components you plan to use
export default {
  components: {
    //templateTodo: define lookup components you are using
  },
  props: {
    dialog: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    valid: true,
    selectedLuIncomeThreshold: new LuIncomeThreshold(),
    headerText: 'LuIncomeThreshold',
  }),
  created() {},
  computed: {
    ...get('luIncomeThreshold', ['saving']),
    inDialog() {
      if (this.dialog === false || this.dialog === true) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    ...call('luIncomeThreshold', ['saveLuIncomeThreshold']),
    async validate() {
      await this.$refs.form.validate()
    },
    editLuIncomeThreshold(entry) {
      this.selectedLuIncomeThreshold = new LuIncomeThreshold(entry)
      this.headerText = 'Edit Income Threshold'
    },
    addLuIncomeThreshold() {
      this.headerText = 'Insert Income Threshold'
      this.selectedLuIncomeThreshold = new LuIncomeThreshold()
    },
    async saveLuIncomeThresholdDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        try {
          let res = await this.saveLuIncomeThreshold(
            this.selectedLuIncomeThreshold.removeRelated()
          )
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedLuIncomeThreshold = new LuIncomeThreshold()
          this.$refs.form.resetValidation()
        } catch (err) {}
        this.$emit('update:dialog', false)
      }
    },
    cancelEntry() {
      this.selectedLuIncomeThreshold = new LuIncomeThreshold()
      this.$refs.form.resetValidation()
      this.$emit('update:dialog', false)
    },
  },
}
</script>
<style></style>
