<template>
  <v-dialog
    persistent
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
    width="850"
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Name"
                v-model="selectedLuCounty.Name"
                :rules="[(v) => !!v || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- CityCounties Join Items-->
          <v-row>
            <v-col sm="12">
              <LuCity
                label="Cities"
                v-model="selectedLuCounty.Cities"
                return-object
              ></LuCity>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-checkbox
                label="Is Active"
                v-model="selectedLuCounty.IsActive"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="saveLuCountyDetails" :disabled="saving" color="primary"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuCounty from '@classes/LuCounty'
//templateTODO: import lookup components you plan to use
import LuCity from '@components/select/LuCity/LuCity-a-multi-id'
export default {
  components: {
    //templateTodo: define lookup components you are using
    LuCity,
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedLuCounty: new LuCounty(),
    modalText: '',
  }),
  created() {},
  computed: {
    ...get('luCounty', ['saving']),
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('luCounty', ['saveLuCounty']),
    async validate() {
      await this.$refs.form.validate()
    },
    editLuCounty(entry) {
      this.selectedLuCounty = new LuCounty(entry)
      this.modalText = 'Edit County'
      this.dialog = !this.dialog
    },
    addLuCounty() {
      this.modalText = 'Insert County'
      this.selectedLuCounty = new LuCounty()
      this.dialog = !this.dialog
    },
    async saveLuCountyDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        try {
          await this.saveLuCounty(this.selectedLuCounty.removeRelated())
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedLuCounty = new LuCounty()
          this.$refs.form.resetValidation()
        } catch (err) {}
        this.dialog = false
      }
    },
    cancelEntry() {
      this.dialog = false
      this.selectedLuCounty = new LuCounty()
      this.$refs.form.resetValidation()
    },
  },
}
</script>
<style></style>
