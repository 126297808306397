<template>
  <BasePanelWithHeader headerText="County Medians">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="filteredCountyMedians"
      :loading="loading"
      search
    >
      <!-- exportExcel
      multi-sort -->
      <template v-slot:prependParams></template>
      <template v-slot:appendParams>
        <!-- <v-row> -->
        <!-- <v-col class="ml-auto" cols="12" sm="auto">
          <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Search"
            clearable
            single-line
            hide-details
          >
          </v-text-field>
        </v-col> -->
        <v-col cols="6" sm="2" md="auto">
          <BaseTooltipButton
            small
            @click="$refs.countyMedianForm.addCountyMedian()"
            icon="mdi-plus"
            iconColor="white"
            buttonClass="primary"
            >Add County Median
          </BaseTooltipButton>
        </v-col>
        <!-- </v-row> -->
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.countyMedianForm.editCountyMedian(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit County Median
        </BaseTooltipButton>
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.countyMedianDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete County Median
        </BaseTooltipButton>
      </template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="countyMedianDelete"
      :delete="deleteCountyMedian"
      @refresh="loadCountyMedians"
    >
      Are you sure you want to delete this county median?
    </BaseDeleteConfirm>
    <CountyMedianDialogForm ref="countyMedianForm" @refresh="loadCountyMedians">
    </CountyMedianDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import CountyMedianDialogForm from '@components/form/add-edit/CountyMedianDialog'
export default {
  components: {
    CountyMedianDialogForm,
  },
  data: () => ({}),
  created() {
    this.loadCountyMedians()
  },
  computed: {
    ...get('countyMedian', ['countyMedians', 'loading']),
    filteredCountyMedians() {
      var list = this.countyMedians.filter((i) => true)
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'County',
          align: 'center',
          sortable: true,
          value: 'County.Name',
          visible: true,
        },
        {
          text: 'Median Income (at 100% refund threshold)',
          align: 'center',
          sortable: true,
          value: 'MedianIncome',
          visible: true,
        },
        {
          text: 'Median Tax',
          align: 'center',
          sortable: true,
          value: 'MedianTax',
          visible: true,
        },
        {
          text: 'Period',
          align: 'center',
          sortable: true,
          value: 'Period.Period',
          visible: true,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('countyMedian', ['deleteCountyMedian', 'loadCountyMedians']),
  },
}
</script>
<style></style>
