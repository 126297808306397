<template>
<!-- Example usage:
  <selectMultiLuRole
    v-model="selectedUser.UserRole" (the collection we are updating)
    selfKey="UserID" (how the selectedUser is referenced in the join item)
    :selfId="selectedUser.ID" (to create new entries we need the selected users ID)
    relatedItemKey="RoleID" (this is how the lookup is referenced in the join)
    joinItemName="UserRole" (this is the name of the join (for the js class))
  ></selectMultiLuRole>
-->
  <v-select
    v-bind="$attrs"
    :items="availableItems"
    :label="label"
    v-model="selected"
    :item-text="itemText"
    item-value="Id"
    :clearable="clearable"
    :rules="rules"
    :loading="loading"
    chips
    deletable-chips
    multiple
    dense
  >
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </v-select>
</template>
<script>
  import { get, sync, commit, call } from 'vuex-pathify'
  import AvailableStatusRole from '@classes/AvailableStatusRole'
  import UserRole from '@classes/UserRole'
  export default {
    name: 'select-multi-join-luRoles',
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      label: {
        type: String,
        default: 'Roles',
        },
      value: Array,
      clearable: {
        type: Boolean,
        default: false,
      },
      includeInactive: {
        type: Boolean,
        default: false,
      },
      rules: Array,
      items: Array,
      //templateFIX: if there is more than one pick one to display
      itemText: {
        type: String,
        default: 'Description',
      },
      sortProperty: {
        type: String,
        default: null,
      },
      joinItemName: {
        type: String,
        required: true,
        //default: 'AvailableStatusRole',
        //default: 'UserRole',
      },
      relatedItemKey: {
        type: String,
        required: true,
        //default: 'RoleId',
        //default: 'RoleId',
      },
      selfKey: {
        type: String,
        required: true,
      },
      selfId: {
        type: Number,
        required: true,
      }
    },
    data(){
      return {
      }
    },
    computed: {
     ...get('luRole',['luRoles', 'loading']),
      availableItems() {
        var list = []
        var fullList = []
        if (this.items) {
          fullList = this.items
          list = this.items.filter((i) => true)
        } else {
          fullList = this.luRoles
          list = this.luRoles.filter((i) => true)
        }
          //filter for only active items
          if (!this.includeInactive) {
            list = list.filter((i) => i.IsActive )
          }
                // if existing record make sure the previous selection is available even if no longer active
          if (this.value > 0) {
            var selectedItem = fullList.find((i) => i.Id == this.value)
            if (!list.find((i) => i.Id == this.value) && selectedItem) {
              list.push(selectedItem)
            }
          }
          // sort by itemText column
          list.sort((a, b) => {
            //set the property to sort on
            let textField = this.sortProperty || this.itemText
            let sort = a[textField] > b[textField] ? 1 : -1
            return sort
          })
          return list
      },
      selected: {
        get() {
          if (this.value && this.value.length > 0) {
            return [
              ...new Set(
                this.value.map((item) => item[this.relatedItemKey])
              ),
            ]
          } else {
            return []
          }
        },
        set(newValue) {
          if (this.value) {
            this.value.forEach(
            (relation, index, object) => {
              if (!newValue.includes(relation[this.relatedItemKey])) {
                //need to remove item
                object.splice(index, 1)
              }
            }
            )
            newValue.forEach((itemId) => {
              if (
                !this.value.find(
                  (i) => i[this.relatedItemKey] == itemId
                )
              ) {
                // need to add item
                let item
                if (this.joinItemName == 'AvailableStatusRole') {
                  item = new AvailableStatusRole()
                }
                else
                if (this.joinItemName == 'UserRole') {
                  item = new UserRole()
                }
                else
                {item = {}}
                item[this.relatedItemKey]= itemId
                item[this.selfKey]= this.selfId
                this.value.push(item)
              }
            })
          }
        }
      }
    },
    created(){
      if (!this.items) {
        this.loadLuRoles()
      }
    },
    methods: {
      loadLuRoles: call('luRole/loadLuRoles'),
    },
  }
</script>
<style>

</style>