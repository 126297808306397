<template>
  <BasePanelWithHeader headerText="Reasons">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="filteredLuReasons"
      :searchText="searchText"
      :loading="loading"
    >
      <template v-slot:searchParams>
        <v-row>
          <v-col cols="12" sm="auto">
            <v-switch v-model="showInactive" label="Show Inactive"></v-switch>
          </v-col>
          <v-col class="ml-auto" cols="12" sm="auto">
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="auto" class="mt-4 mr-4">
            <BaseTooltipButton
              small
              @click="$refs.luReasonForm.addLuReason()"
              icon="mdi-plus"
              iconColor="white"
              buttonClass="primary"
              >Add Reason
            </BaseTooltipButton>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luReasonForm.editLuReason(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Reason
        </BaseTooltipButton>
      </template>
      <template v-slot:item.IsActive="{ item }">
        {{ item.IsActive ? 'Yes' : 'No' }}
      </template>
      <template v-slot:item.NextReason="{ item }">
        <span v-if="item.NextReason">
          {{ item.NextReason?.ReasonNumber }} -
          {{ item.NextReason?.Description }}
        </span>
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luReasonDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete Reason
        </BaseTooltipButton>
      </template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="luReasonDelete"
      :delete="deleteLuReason"
      @refresh="clearLookupCacheAndReloadLuReasons"
    >
      Are you sure you want to delete this reason?
    </BaseDeleteConfirm>
    <LuReasonDialogForm
      ref="luReasonForm"
      @refresh="clearLookupCacheAndReloadLuReasons"
    >
    </LuReasonDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuReasonDialogForm from '@components/form/add-edit/LuReasonDialog'
export default {
  components: {
    LuReasonDialogForm,
  },
  data: () => ({
    searchText: '',
    showInactive: false,
  }),
  created() {
    this.loadLuReasons()
  },
  computed: {
    ...get('luReason', ['luReasons', 'loading']),
    filteredLuReasons() {
      var list = this.luReasons.filter((i) => true)
      if (!this.showInactive) {
        list = list.filter((i) => i.IsActive)
      }
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Reason Number',
          align: 'center',
          sortable: true,
          value: 'ReasonNumber',
          visible: true,
        },
        {
          text: 'Description',
          align: 'left',
          sortable: true,
          value: 'Description',
          visible: true,
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          value: 'Status.Description',
          visible: true,
        },
        {
          text: 'Validation',
          align: 'left',
          sortable: true,
          value: 'Validation.Description',
          visible: true,
        },
        {
          text: 'Next Reason',
          align: 'left',
          sortable: true,
          value: 'NextReason',
          visible: true,
        },
        {
          text: 'Next Reason Days',
          align: 'center',
          sortable: true,
          value: 'NextReasonDays',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: this.showInactive,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('luReason', [
      'deleteLuReason',
      'loadLuReasons',
      'clearLookupCacheAndReloadLuReasons',
    ]),
  },
}
</script>
<style></style>
