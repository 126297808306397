<template>
  <BasePanelWithHeader headerText="Next Available Statuses">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="filteredNextAvailableStatuses"
      :searchText="searchText"
      :loading="loading"
    >
      <template v-slot:searchParams>
        <v-row>
          <v-col class="ml-auto" cols="12" sm="auto">
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="auto" class="mt-4 mr-4">
            <BaseTooltipButton
              small
              @click="
                $refs.nextAvailableStatusForm.addNextAvailableStatus(statusId)
              "
              icon="mdi-plus"
              iconColor="white"
              buttonClass="primary"
              >Add Next Available Status
            </BaseTooltipButton>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.nextAvailableStatusForm.editNextAvailableStatus(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Next Available Status
        </BaseTooltipButton>
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.nextAvailableStatusDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete Next Available Status
        </BaseTooltipButton>
      </template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="nextAvailableStatusDelete"
      :delete="deleteNextAvailableStatus"
      @refresh="$emit('refresh')"
    >
      Are you sure you want to delete this next available status?
    </BaseDeleteConfirm>
    <NextAvailableStatusDialogForm
      ref="nextAvailableStatusForm"
      @refresh="$emit('refresh')"
      @itemAdded="$emit('itemAdded', $event)"
    >
      >
    </NextAvailableStatusDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import NextAvailableStatusDialogForm from '@components/form/add-edit/NextAvailableStatusDialog'
export default {
  components: {
    NextAvailableStatusDialogForm,
  },
  props: {
    items: {
      type: Array,
    },
    statusId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    searchText: '',
  }),
  created() {
    // this.loadNextAvailableStatuses()
  },
  computed: {
    ...get('nextAvailableStatus', ['loading']),
    filteredNextAvailableStatuses() {
      var list = this.items.filter((i) => true)
      list = list.map((i) => {
        return {
          ...i,
          roles: i.Roles.map((r) => r.Description).join(', '),
        }
      })
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Current Status Id',
          align: 'center',
          sortable: true,
          value: 'CurrentStatusId',
          visible: false,
        },
        {
          text: 'Next Status',
          align: 'center',
          sortable: true,
          value: 'NextStatus.Description',
          visible: true,
        },
        {
          text: 'Roles',
          align: 'center',
          sortable: true,
          value: 'roles',
          visible: true,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: true,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('nextAvailableStatus', [
      'deleteNextAvailableStatus',
      // 'loadNextAvailableStatuses',
    ]),
  },
}
</script>
<style></style>
