<template>
  <BasePanelWithHeader headerText="User Search">
    <BaseServerSyncDataTable
      :headers="filteredHeaders"
      :items="users"
      :searchText="searchText"
      :search="true"
      itemKey="Id"
      :loading="loading"
      :searchTotal="searchTotal"
      :searchSortBy.sync="searchSortBy"
      :searchDescending.sync="searchDescending"
      :searchPage.sync="searchPage"
      :searchRowsPerPage.sync="searchRowsPerPage"
    >
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.userForm.editUser(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit User
        </BaseTooltipButton>
      </template>
      <template v-slot:item.IsActive="{ item }">
        {{ item.IsActive ? 'Yes' : 'No' }}
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.userDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete User
        </BaseTooltipButton>
      </template>
      <template v-slot:item.Roles="{ item }">
        {{
          item.Roles.filter((x) => x.IsActive)
            .map((x) => x.Description)
            .join(', ')
        }}</template
      >
      <!-- //templateTodo: uncomment use custom search params-->
      <template v-slot:searchParams>
        <UserParams @refresh="searchUsers"></UserParams>
      </template>
    </BaseServerSyncDataTable>
    <BaseDeleteConfirm
      ref="userDelete"
      :delete="deleteUser"
      @refresh="searchUsers"
    >
      Are you sure you want to delete this user?
    </BaseDeleteConfirm>
    <UserDialogForm ref="userForm" @refresh="searchUsers"> </UserDialogForm>
  </BasePanelWithHeader>
</template>

<script>
import { get, sync, commit, call } from 'vuex-pathify'
//templateTodo: uncomment use custom search params
import UserParams from '@components/search/UserParams'
import UserDialogForm from '@components/form/add-edit/UserDialog'
export default {
  name: 'UserSearchTable',
  components: {
    //templateTodo: uncomment use custom search params
    UserParams,
    UserDialogForm,
  },
  data: () => ({}),
  created() {
    this.searchUsers()
  },
  computed: {
    ...get('user', ['users', 'loading', 'searchShowInactive']),
    ...sync('user', [
      'searchTotal',
      'searchPage',
      'searchSortBy',
      'searchDescending',
      'searchRowsPerPage',
      'searchText',
    ]),
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'First Name',
          align: 'center',
          sortable: true,
          value: 'FirstName',
          visible: true,
        },
        {
          text: 'Last Name',
          align: 'center',
          sortable: true,
          value: 'LastName',
          visible: true,
        },
        {
          text: 'Email',
          align: 'center',
          sortable: true,
          value: 'Email',
          visible: true,
        },
        {
          text: 'Roles',
          align: 'center',
          sortable: true,
          value: 'Roles',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: this.searchShowInactive,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: true,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('user', ['searchUsers', 'deleteUser']),
  },
}
</script>

<style></style>
