<template>
  <v-dialog persistent v-model="dialog" scrollable @keydown.esc="dialog = false" width="850">
    <NextAvailableStatusForm 
      ref="nextAvailableStatusForm" 
      :dialog.sync="dialog" 
      @refresh="$emit('refresh')" 
      @itemAdded="$emit('itemAdded', $event)">
    </NextAvailableStatusForm>
  </v-dialog>
</template>
<script>
  import NextAvailableStatusForm from '@components/form/add-edit/NextAvailableStatusForm'
export default {
  components: {
    NextAvailableStatusForm,
  },
  data: () => ({
    dialog: false,
  }),
  created() {},
  computed: {
  },
  methods: {
    editNextAvailableStatus(entry) {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.nextAvailableStatusForm.editNextAvailableStatus(entry)
      })
    },
    addNextAvailableStatus(currentStatusId) {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.nextAvailableStatusForm.addNextAvailableStatus(currentStatusId)
      })
    },
  },
  } 
  
</script>
<style>

</style>