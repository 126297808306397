<template>
  <BasePanelWithHeader headerText="Counties">
    <BaseSimpleDataTable
      item-key="Id"
      :headers="filteredHeaders"
      :items="filteredLuCounties"
      :searchText="searchText"
      :loading="loading"
      class="pt-4"
      dense
      :expanded.sync="expanded"
      single-expand
      show-expand
    >
      <template v-slot:searchParams>
        <v-row>
          <v-col cols="12" sm="auto">
            <v-switch v-model="showInactive" label="Show Inactive"></v-switch>
          </v-col>
          <v-col class="ml-auto" cols="12" sm="auto">
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="auto" class="mt-4 mr-4">
            <BaseTooltipButton
              small
              @click="$refs.luCountyForm.addLuCounty()"
              icon="mdi-plus"
              iconColor="white"
              buttonClass="primary"
              >Add County
            </BaseTooltipButton>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luCountyForm.editLuCounty(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit County
        </BaseTooltipButton>
      </template>
      <template v-slot:item.cities="{ item, headers }">
        <span @click="expandRow(item)">
          {{ item.cities | snippet(50) }}
        </span>
      </template>
      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="pa-2">
          {{ item.cities }}
        </td>
      </template>
      <template v-slot:item.IsActive="{ item }">
        {{ item.IsActive ? 'Yes' : 'No' }}
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luCountyDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete County
        </BaseTooltipButton>
      </template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="luCountyDelete"
      :delete="deleteLuCounty"
      @refresh="clearLookupCacheAndReloadLuCounties"
    >
      Are you sure you want to delete this county?
    </BaseDeleteConfirm>
    <LuCountyDialogForm
      ref="luCountyForm"
      @refresh="clearLookupCacheAndReloadLuCounties"
    >
    </LuCountyDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuCountyDialogForm from '@components/form/add-edit/LuCountyDialog'
export default {
  components: {
    LuCountyDialogForm,
  },
  data: () => ({
    searchText: '',
    showInactive: false,
    expanded: [],
  }),
  created() {
    this.loadLuCounties()
  },
  computed: {
    ...get('luCounty', ['luCounties', 'loading']),
    filteredLuCounties() {
      var list = this.luCounties.filter((i) => true)
      if (!this.showInactive) {
        list = list.filter((i) => i.IsActive)
      }
      list = list.map((item) => {
        let name = item.Cities.map((i) => i.Name)
        return {
          ...item,
          cities: name.join(', '),
        }
      })
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Name',
          align: 'center',
          sortable: true,
          value: 'Name',
          visible: true,
        },
        {
          text: 'Cities',
          align: 'center',
          sortable: true,
          value: 'cities',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: this.showInactive,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('luCounty', [
      'deleteLuCounty',
      'loadLuCounties',
      'clearLookupCacheAndReloadLuCounties',
    ]),
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item]
    },
  },
}
</script>
<style></style>
