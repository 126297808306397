const defaults = {
  //Id is required
  Id: 0,
  //IncomePercentage is required
  IncomePercentage: null,
  //RefundPercentage is required
  RefundPercentage: null,
  //EffectiveDate is required
  EffectiveDate: new Date().toISOString().split('T')[0],
  ExpirationDate: null,
}
export default class LuIncomeThreshold {
  constructor(objIn = {}) {
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.IncomePercentage = obj.IncomePercentage
    this.RefundPercentage = obj.RefundPercentage
    this.EffectiveDate = obj.EffectiveDate
    this.ExpirationDate = obj.ExpirationDate
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    return this.equalsDefault() ? null : obj
  }
  root() {
    let obj = Object.assign({}, this)
    return this.equalsDefault() ? null : obj
  }
  equalsDefault() {
    let obj = Object.assign({}, this)
    let compare = {
      Id: obj.Id,
      IncomePercentage: obj.IncomePercentage,
      RefundPercentage: obj.RefundPercentage,
      EffectiveDate: obj.EffectiveDate,
      ExpirationDate: obj.ExpirationDate,
    }
    return JSON.stringify(defaults) === JSON.stringify(compare)
  }
}
