<template>
  <v-dialog
    persistent
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
    width="850"
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Description"
                v-model="selectedLuTemplateDocument.Description"
                :rules="[(v) => !!v || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="selectedLuTemplateDocument.DocumentId == 0" no-gutters>
            <v-col sm="12">
              <div class="dropbox">
                <input class="input-file" type="file" @change="getFiles" />
                <p v-if="uploadDocs.length == 0" class="extra-margin"
                  >Drag file to upload or click to browse</p
                >
                <div v-if="uploadDocs.length > 0" class="extra-margin">
                  <p
                    class="uploadList"
                    v-for="(item, index) in items"
                    :key="index"
                    >{{ item.name }}</p
                  >
                </div>
              </div>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col sm="12">
              <Document
                label="Document"
                v-model="selectedLuTemplateDocument.DocumentId"
                  :rules="[v => !!v || 'required']"
              ></Document>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="selectedLuTemplateDocument.DocumentId > 0"
            @click="saveLuTemplateDocumentDetails"
            :disabled="saving"
            color="primary"
            >Save</v-btn
          >
          <v-btn
            v-if="selectedLuTemplateDocument.DocumentId > 0"
            @click="uploadNewDocumentDialog"
            :disabled="saving"
            color="primary"
            >Replace Template</v-btn
          >
          <v-btn
            v-else
            @click="uploadDocument"
            :disabled="saving"
            color="primary"
            >Upload</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
        <v-dialog
          persistent
          v-model="newDocDialog"
          scrollable
          @keydown.esc="newDocDialog = false"
          width="850"
        >
          <v-form @submit.prevent>
            <v-card class="elevation-3">
              <v-card-title class="primary text-h5 white--text">
                Upload New Template
              </v-card-title>
              <v-card-text class="pa-4">
                <v-row no-gutters>
                  <v-col sm="12">
                    <div class="dropbox">
                      <input
                        class="input-file"
                        type="file"
                        @change="getFiles"
                      />
                      <p v-if="uploadDocs.length == 0" class="extra-margin"
                        >Drag file to upload or click to browse</p
                      >
                      <div v-if="uploadDocs.length > 0" class="extra-margin">
                        <p
                          class="uploadList"
                          v-for="(item, index) in items"
                          :key="index"
                          >{{ item.name }}</p
                        >
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  @click="uploadNewDocument"
                  :disabled="saving"
                  color="primary"
                  >Upload</v-btn
                >
                <v-btn outlined color="primary" @click="newDocDialog = false"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import download from 'downloadjs'
import { get, sync, commit, call } from 'vuex-pathify'
import LuTemplateDocument from '@classes/LuTemplateDocument'
import Document from '@classes/Document'
//templateTODO: import lookup components you plan to use

export default {
  components: {
    //templateTodo: define lookup components you are using
    //Document,
  },
  data: () => ({
    dialog: false,
    newDocDialog: false,
    valid: true,
    selectedLuTemplateDocument: new LuTemplateDocument(),
    modalText: '',
    uploadDocs: [],
    items: [],
  }),
  created() {},
  computed: {
    ...sync('luTemplateDocument', ['saving']),
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('luTemplateDocument', [
      'saveLuTemplateDocument',
      'clearLookupCacheAndReloadLuTemplateDocuments',
    ]),
    async validate() {
      await this.$refs.form.validate()
    },
    uploadNewDocumentDialog() {
      this.newDocDialog = true
      this.uploadDocs = []
      this.items = []
      this.selectedLuTemplateDocument.Document = new Document()
    },
    uploadNewDocument() {
      try {
        for (let i = 0; i < this.uploadDocs.length - 2; i++) {
          let file = this.uploadDocs[i]
          let fileReader = new FileReader()
          if (fileReader && file) {
            fileReader.readAsDataURL(file)
            fileReader.onload = async () => {
              let templateDoc = this.selectedLuTemplateDocument.getSaveData()
              let contents = fileReader.result.split(',')[1]
              templateDoc.DocumentId = 0
              templateDoc.Document.FileName = file.name
              templateDoc.Document.MimeType = file.type
              templateDoc.Document.DocumentTypeId = 1
              templateDoc.Document.DocumentFile = { File: contents }
              let res = await this.saveLuTemplateDocument(templateDoc)
              this.selectedLuTemplateDocument.DocumentId = res.data.DocumentId
              this.saving = false
              this.$emit('refresh')
              this.newDocDialog = false
            }
          }
        }
      } catch {
        this.saving = false
      }
    },
    editLuTemplateDocument(entry) {
      this.selectedLuTemplateDocument = new LuTemplateDocument(entry)
      this.modalText = 'Edit Template Document'
      this.dialog = !this.dialog
    },
    addLuTemplateDocument() {
      this.modalText = 'Insert Template Document'
      this.selectedLuTemplateDocument = new LuTemplateDocument()
      this.uploadDocs = []
      this.items = []
      this.dialog = !this.dialog
    },
    getFiles: function(event) {
      this.uploadDocs = []
      for (let file in event.target.files) {
        this.uploadDocs.push(event.target.files[file])
      }
      for (let i = 0; i < this.uploadDocs.length - 2; i++) {
        if (this.uploadDocs[i].name) this.items.push(this.uploadDocs[i])
      }
    },
    uploadDocument() {
      this.validate().then(() => {
        if (this.valid) {
          var self = this
          var docType = 1
          this.saving = true
          for (let i = 0; i < this.uploadDocs.length - 2; i++) {
            let file = this.uploadDocs[i]
            let fileReader = new FileReader()
            if (fileReader && file) {
              fileReader.readAsDataURL(file)
              fileReader.onload = function() {
                let contents = fileReader.result.split(',')[1]
                let obj = {
                  Id: 0,
                  Description: self.selectedLuTemplateDocument.Description,
                  DocumentId: 0,
                  Document: {
                    Id: 0,
                    FileName: file.name,
                    MimeType: file.type,
                    DocumentTypeId: docType,
                    DocumentFile: {
                      File: contents,
                    },
                  },
                }
                axios
                  .post(`/LuTemplateDocument/`, obj)
                  .then(function(response) {
                    self.dialog = false
                    self.items = []
                    self.upload = []
                    self.clearLookupCacheAndReloadLuTemplateDocuments()
                  })
                  .catch(function(error) {
                    console.error(error)
                    self.handleError(error)
                    // this.error = error
                    // this.displayError = true
                  })
              }
            }
          }
          this.saving = false
        }
      })
    },
    downloadDocument(doc) {
      let url
      url = `LuTemplateDocument/Download/${doc.Id}`
      let headers = { responseType: 'blob' }
      this.$axios.get(url, headers).then(
        (res) => {
          const content = res.headers['content-type']
          const dispo = res.headers['content-disposition']
          var filename = ''
          if (dispo && dispo.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            var matches = filenameRegex.exec(dispo)
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '')
            }
          }
          download(res.data, filename, content)
        },
        (error) => {
          this.handleError(error)
          // this.error = error
          // this.displayError = true
          console.error(error)
        }
      )
    },
    async saveLuTemplateDocumentDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        try {
          await this.saveLuTemplateDocument(
            this.selectedLuTemplateDocument.removeRelated()
          ).then((res) => {
            this.clearLookupCacheAndReloadLuTemplateDocuments()
            this.selectedLuTemplateDocument = new LuTemplateDocument()
            this.$refs.form.resetValidation()
            this.$emit('refresh')
            this.$emit('itemAdded', res.data)
          })
        } catch (err) {}
        this.dialog = false
      }
    },
    cancelEntry() {
      this.dialog = false
      this.selectedLuTemplateDocument = new LuTemplateDocument()
      this.$refs.form.resetValidation()
    },
  },
}
</script>
<style>
.dropbox {
  outline: 2px dashed rgb(190, 190, 190); /* the dash box */
  outline-offset: -10px;
  background: #fafafa;
  color: dimgray;
  padding: 10px 10px;
  position: relative;
  cursor: pointer;
  /* width: 20%; */
  height: 100px;
  /* margin-left: auto;
  margin-right: auto;  */
  margin-top: 10px;
}
.input-file {
  outline: 2px solid black;
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}
</style>
