<template>
  <BasePanelWithHeader headerText="Periods">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="filteredLuPeriods"
      :searchText="searchText"
      :loading="loading"
    >
      <template v-slot:searchParams>
        <v-row>
          <v-col class="ml-auto" cols="12" sm="auto">
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="auto" class="mt-4 mr-4">
            <BaseTooltipButton
              small
              @click="$refs.luPeriodForm.addLuPeriod()"
              icon="mdi-plus"
              iconColor="white"
              buttonClass="primary"
              >Add Period
            </BaseTooltipButton>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luPeriodForm.editLuPeriod(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Period
        </BaseTooltipButton>
      </template>
      <template v-slot:item.AssetMaximum="{ item }">{{
        item.AssetMaximum | formatMoney
      }}</template>
      <template v-slot:item.HouseholdMaximum="{ item }">{{
        item.HouseholdMaximum | formatMoney
      }}</template>
      <template v-slot:item.ApplicationOpenDate="{ item }">{{
        item.ApplicationOpenDate | formatDate
      }}</template>
      <template v-slot:item.ApplicationDeadline="{ item }">{{
        item.ApplicationDeadline | formatDate
      }}</template>
      <template v-slot:item.Payment1Deadline="{ item }">{{
        item.Payment1Deadline | formatDate
      }}</template>
      <template v-slot:item.Payment2Deadline="{ item }">{{
        item.Payment2Deadline | formatDate
      }}</template>
      <template v-slot:item.FullPaymentDeadline="{ item }">{{
        item.FullPaymentDeadline | formatDate
      }}</template>
      <template v-slot:item.PeriodEndDate="{ item }">{{
        item.PeriodEndDate | formatDate
      }}</template>
      <template v-slot:item.PercentageEligible="{ item }">{{
        item.PercentageEligible * 100 + '%'
      }}</template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luPeriodDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete Period
        </BaseTooltipButton>
      </template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="luPeriodDelete"
      :delete="deleteLuPeriod"
      @refresh="clearLookupCacheAndReloadLuPeriods"
    >
      Are you sure you want to delete this period?
    </BaseDeleteConfirm>
    <LuPeriodDialogForm
      ref="luPeriodForm"
      @refresh="clearLookupCacheAndReloadLuPeriods"
    >
    </LuPeriodDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuPeriodDialogForm from '@components/form/add-edit/LuPeriodDialog'
export default {
  components: {
    LuPeriodDialogForm,
  },
  data: () => ({
    searchText: '',
  }),
  created() {
    this.loadLuPeriods()
  },
  computed: {
    ...get('luPeriod', ['luPeriods', 'loading']),
    filteredLuPeriods() {
      var list = this.luPeriods.filter((i) => true)
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Period',
          align: 'center',
          sortable: true,
          value: 'Period',
          visible: true,
        },
        {
          text: 'Application Open Date',
          align: 'center',
          sortable: true,
          value: 'ApplicationOpenDate',
          visible: true,
        },
        {
          text: 'Application Deadline',
          align: 'center',
          sortable: true,
          value: 'ApplicationDeadline',
          visible: true,
        },
        {
          text: 'Period End Date',
          align: 'center',
          sortable: true,
          value: 'PeriodEndDate',
          visible: true,
        },
        {
          text: 'Payment 1 Deadline',
          align: 'center',
          sortable: true,
          value: 'Payment1Deadline',
          visible: true,
        },
        {
          text: 'Payment 2 Deadline',
          align: 'center',
          sortable: true,
          value: 'Payment2Deadline',
          visible: true,
        },
        {
          text: 'Full Payment Deadline',
          align: 'center',
          sortable: true,
          value: 'FullPaymentDeadline',
          visible: true,
        },
        {
          text: 'Asset Maximum',
          align: 'center',
          sortable: true,
          value: 'AssetMaximum',
          visible: true,
        },
        {
          text: 'Household Maximum',
          align: 'center',
          sortable: true,
          value: 'HouseholdMaximum',
          visible: true,
        },
        {
          text: 'Percentage Eligible',
          align: 'center',
          sortable: true,
          value: 'PercentageEligible',
          visible: true,
        },
        {
          text: 'Budget Fiscal Year',
          align: 'center',
          sortable: true,
          value: 'BudgetFiscalYear',
          visible: true,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('luPeriod', [
      'deleteLuPeriod',
      'loadLuPeriods',
      'clearLookupCacheAndReloadLuPeriods',
    ]),
  },
}
</script>
<style></style>
