<template>
  <v-dialog
    persistent
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
    width="850"
  >
    <LuIncomeThresholdForm
      ref="LuIncomeThresholdForm"
      :dialog.sync="dialog"
      @refresh="$emit('refresh')"
      @itemAdded="$emit('itemAdded', $event)"
    >
    </LuIncomeThresholdForm>
  </v-dialog>
</template>
<script>
import LuIncomeThresholdForm from '@components/form/LuIncomeThreshold-form.vue'
export default {
  components: {
    LuIncomeThresholdForm,
  },
  data: () => ({
    dialog: false,
  }),
  created() {},
  computed: {},
  methods: {
    editLuIncomeThreshold(entry) {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.LuIncomeThresholdForm.editLuIncomeThreshold(entry)
      })
    },
    addLuIncomeThreshold() {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.LuIncomeThresholdForm.addLuIncomeThreshold()
      })
    },
  },
}
</script>
<style></style>
