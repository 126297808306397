<template>
  <v-dialog persistent v-model="dialog" scrollable @keydown.esc="dialog = false" width="850">
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
            {{modalText}}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              <v-text-field 
                label="Description" 
                v-model="selectedLuDocumentType.Description"
                :rules="[v => !!v || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-checkbox 
                label="Internal Only" 
                v-model="selectedLuDocumentType.InternalOnly"
                :rules="[v => v === true || v === false || 'required']"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-checkbox 
                label="Is Active" 
                v-model="selectedLuDocumentType.IsActive"
                :rules="[v => v === true || v === false || 'required']"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="saveLuDocumentTypeDetails" :disabled="saving" color="primary">Save</v-btn>
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuDocumentType from '@classes/LuDocumentType'
//templateTODO: import lookup components you plan to use
export default {
  components: {
    //templateTodo: define lookup components you are using
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedLuDocumentType: new LuDocumentType(),
    modalText: '',
  }),
  created() {},
  computed: {
  ...get('luDocumentType', ['saving']),
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('luDocumentType', [
    'saveLuDocumentType'
    ]),
    async validate() {
      await this.$refs.form.validate()
    },
    editLuDocumentType(entry) {
      this.selectedLuDocumentType = new LuDocumentType(entry)
      this.modalText = 'Edit Document Type'
      this.dialog = !this.dialog
    },
    addLuDocumentType() {
      this.modalText = 'Insert Document Type'
      this.selectedLuDocumentType = new LuDocumentType()
      this.dialog = !this.dialog
    },
    async saveLuDocumentTypeDetails() {
      await this.validate()
        if (this.valid) {
          //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
          try {
            await this.saveLuDocumentType(this.selectedLuDocumentType.removeRelated())
            this.$emit('refresh')
            this.$emit('itemAdded', res.data)
            this.selectedLuDocumentType = new LuDocumentType()
            this.$refs.form.resetValidation()
          } catch (err) {
          }
          this.dialog = false
        }
    },
    cancelEntry() {
      this.dialog = false 
      this.selectedLuDocumentType = new LuDocumentType()
      this.$refs.form.resetValidation()
    }
  },
  } 
  
</script>
<style>

</style>