<template>
  <BasePanelWithHeader headerText="Income Thresholds">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="filteredLuIncomeThresholds"
      :loading="loading"
      search
    >
      <template v-slot:prependParams> </template>
      <template v-slot:appendParams>
        <v-col cols="12" sm="auto" class="mt-4 mr-4">
          <BaseTooltipButton
            small
            @click="$refs.luIncomeThresholdForm.addLuIncomeThreshold()"
            icon="mdi-plus"
            iconColor="white"
            buttonClass="primary"
            >Add Income Threshold
          </BaseTooltipButton>
        </v-col>
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luIncomeThresholdForm.editLuIncomeThreshold(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Income Threshold
        </BaseTooltipButton>
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luIncomeThresholdDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete Income Threshold
        </BaseTooltipButton>
      </template>
      <template v-slot:item.EffectiveDate="{ item }">{{
        item.EffectiveDate | formatDate
      }}</template>
      <template v-slot:item.ExpirationDate="{ item }">{{
        item.ExpirationDate | formatDate
      }}</template>
      <template v-slot:item.RefundPercentage="{ item }">{{
        item.RefundPercentage * 100 + '%'
      }}</template>
      <template v-slot:item.IncomePercentage="{ item }">{{
        item.IncomePercentage * 100 + '%'
      }}</template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="luIncomeThresholdDelete"
      :delete="deleteLuIncomeThreshold"
      @refresh="clearLookupCacheAndReloadLuIncomeThresholds"
    >
      Are you sure you want to delete this income threshold?
    </BaseDeleteConfirm>
    <LuIncomeThresholdDialogForm
      ref="luIncomeThresholdForm"
      @refresh="clearLookupCacheAndReloadLuIncomeThresholds"
    >
    </LuIncomeThresholdDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuIncomeThresholdDialogForm from '@components/dialog/luIncomeThreshold-dialog.vue'
export default {
  components: {
    LuIncomeThresholdDialogForm,
  },
  data: () => ({}),
  created() {
    this.loadLuIncomeThresholds()
  },
  computed: {
    ...get('luIncomeThreshold', ['luIncomeThresholds', 'loading']),
    filteredLuIncomeThresholds() {
      var list = this.luIncomeThresholds.filter((i) => true)
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Income Percentage',
          align: 'center',
          sortable: true,
          value: 'IncomePercentage',
          visible: true,
        },
        {
          text: 'Refund Percentage',
          align: 'center',
          sortable: true,
          value: 'RefundPercentage',
          visible: true,
        },
        {
          text: 'Effective Date',
          align: 'center',
          sortable: true,
          value: 'EffectiveDate',
          visible: true,
        },
        {
          text: 'Expiration Date',
          align: 'center',
          sortable: true,
          value: 'ExpirationDate',
          visible: true,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('luIncomeThreshold', [
      'deleteLuIncomeThreshold',
      'loadLuIncomeThresholds',
      'clearLookupCacheAndReloadLuIncomeThresholds',
    ]),
  },
}
</script>
<style></style>@/src/components/dialog/luIncomeThreshold-dialog.vue
