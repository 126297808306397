<template>
  <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
    <v-card class="elevation-3">
      <v-card-title class="primary text-h5 white--text">
        {{ headerText }}
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Description"
              v-model="selectedLuFundingSource.Description"
              :rules="[(v) => !!v || 'required']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-checkbox
              label="Is Active"
              v-model="selectedLuFundingSource.IsActive"
              :rules="[(v) => v === true || v === false || 'required']"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Agency Code"
              v-model="selectedLuFundingSource.AgencyCode"
              :rules="[(v) => !!v || 'required']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Fund Code"
              v-model="selectedLuFundingSource.FundCode"
              :rules="[(v) => !!v || 'required']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Object Code"
              v-model="selectedLuFundingSource.ObjectCode"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Sub Object Code"
              v-model="selectedLuFundingSource.SubObjectCode"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Unit Code"
              v-model="selectedLuFundingSource.UnitCode"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Appropriation Unit"
              v-model="selectedLuFundingSource.AppropriationUnit"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Activity Code"
              v-model="selectedLuFundingSource.ActivityCode"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row>
            <v-col cols="12">
              <LuFundingSourceType
                label="Funding Source Type"
                v-model="selectedLuFundingSource.FundingSourceTypeId"
                  :rules="[v => !!v || 'required']"
              ></LuFundingSourceType>
            </v-col>
          </v-row> -->
        <!-- <v-row>
            <v-col cols="12">
              <LuFundingSponsor
                label="Funding Sponsor"
                v-model="selectedLuFundingSource.FundingSponsorId"
              ></LuFundingSponsor>
            </v-col>
          </v-row> -->
        <!-- FundingAppropriations Join Items-->
        <!-- <v-row>
            <v-col cols="12">
              <LuPeriod
                label="Period"
                v-model="selectedLuFundingSource.FundingAppropriations"
                selfKey="FundingSourceId"
                :selfId="selectedLuFundingSource.Id"
                relatedItemKey="PeriodId"
                joinItemName="FundingAppropriation"
              ></LuPeriod>
            </v-col>
          </v-row> -->
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="saveLuFundingSourceDetails"
          :disabled="saving"
          color="primary"
          >Save</v-btn
        >
        <v-btn v-if="inDialog" outlined color="primary" @click="cancelEntry"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuFundingSource from '@classes/LuFundingSource'
//templateTODO: import lookup components you plan to use
//import LuFundingSourceType from '@components/select/LuFundingSourceType/LuFundingSourceType-single'
//import LuFundingSponsor from '@components/select/LuFundingSponsor/LuFundingSponsor-single'
//import LuPeriod from '@components/select/LuPeriod/LuPeriod-multi-join'
export default {
  components: {
    //templateTodo: define lookup components you are using
    //LuFundingSourceType,
    //LuFundingSponsor,
    //LuPeriod
  },
  props: {
    dialog: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    valid: true,
    selectedLuFundingSource: new LuFundingSource(),
    headerText: 'LuFundingSource',
  }),
  created() {},
  computed: {
    ...get('luFundingSource', ['saving']),
    inDialog() {
      if (this.dialog === false || this.dialog === true) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    ...call('luFundingSource', ['saveLuFundingSource']),
    async validate() {
      await this.$refs.form.validate()
    },
    editLuFundingSource(entry) {
      this.selectedLuFundingSource = new LuFundingSource(entry)
      this.headerText = 'Edit Funding Source'
    },
    addLuFundingSource() {
      this.headerText = 'Insert Funding Source'
      this.selectedLuFundingSource = new LuFundingSource()
    },
    async saveLuFundingSourceDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        try {
          let res = await this.saveLuFundingSource(
            this.selectedLuFundingSource.removeRelated()
          )
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedLuFundingSource = new LuFundingSource()
          this.$refs.form.resetValidation()
        } catch (err) {}
        this.$emit('update:dialog', false)
      }
    },
    cancelEntry() {
      this.selectedLuFundingSource = new LuFundingSource()
      this.$refs.form.resetValidation()
      this.$emit('update:dialog', false)
    },
  },
}
</script>
<style></style>
