<template>
  <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
    <v-card class="elevation-3">
      <v-card-title class="primary text-h5 white--text">
        {{ headerText }}
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12" md="6">
            <BaseCurrencyField
              label="Original Amount"
              v-model="selectedFundingAppropriation.OriginalAmount"
              :rules="[(v) => parseFloat(v) == parseFloat(v) || 'required']"
            ></BaseCurrencyField>
          </v-col>
          <v-col cols="12" md="6">
            <BaseCurrencyField
              label="Administrative Fee"
              v-model="selectedFundingAppropriation.AdministrativeFee"
              :rules="[(v) => parseFloat(v) == parseFloat(v) || 'required']"
            ></BaseCurrencyField>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <LuFundingSource
              label="Funding Source"
              v-model="selectedFundingAppropriation.FundingSourceId"
              :rules="[(v) => !!v || 'required']"
            ></LuFundingSource>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <LuPeriod
              label="Period"
              v-model="selectedFundingAppropriation.PeriodId"
              itemText="Period"
              :rules="[(v) => !!v || 'required']"
            ></LuPeriod>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <BaseCurrencyField
              label="Current Amount"
              disabled
              v-model="selectedFundingAppropriation.CurrentAmount"
              :rules="[(v) => parseFloat(v) == parseFloat(v) || 'required']"
            ></BaseCurrencyField>
          </v-col>

          <v-col cols="12" md="3">
            <BaseCurrencyField
              label="Committed Amount"
              disabled
              v-model="selectedFundingAppropriation.CommittedAmount"
              :rules="[(v) => parseFloat(v) == parseFloat(v) || 'required']"
            ></BaseCurrencyField>
          </v-col>

          <v-col cols="12" md="3">
            <BaseCurrencyField
              label="Disbursed Amount"
              disabled
              v-model="selectedFundingAppropriation.DisbursedAmount"
              :rules="[(v) => parseFloat(v) == parseFloat(v) || 'required']"
            ></BaseCurrencyField>
          </v-col>

          <v-col cols="12" md="3">
            <BaseCurrencyField
              label="Available Amount"
              disabled
              v-model="selectedFundingAppropriation.AvailableAmount"
              :rules="[(v) => parseFloat(v) == parseFloat(v) || 'required']"
            ></BaseCurrencyField>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="saveFundingAppropriationDetails"
          :disabled="saving"
          color="primary"
          >Save</v-btn
        >
        <v-btn v-if="inDialog" outlined color="primary" @click="cancelEntry"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import FundingAppropriation from '@classes/FundingAppropriation'
//templateTODO: import lookup components you plan to use
import LuFundingSource from '@components/select/LuFundingSource/LuFundingSource-single'
import LuPeriod from '@components/select/LuPeriod/LuPeriod-single'
export default {
  components: {
    //templateTodo: define lookup components you are using
    LuFundingSource,
    LuPeriod,
  },
  props: {
    dialog: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    valid: true,
    selectedFundingAppropriation: new FundingAppropriation(),
    headerText: 'FundingAppropriation',
  }),
  created() {},
  computed: {
    ...get('fundingAppropriation', ['saving']),
    inDialog() {
      if (this.dialog === false || this.dialog === true) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    ...call('fundingAppropriation', ['saveFundingAppropriation']),
    async validate() {
      await this.$refs.form.validate()
    },
    editFundingAppropriation(entry) {
      this.selectedFundingAppropriation = new FundingAppropriation(entry)
      this.headerText = 'Edit Funding Appropriation'
    },
    addFundingAppropriation() {
      this.headerText = 'Insert Funding Appropriation'
      this.selectedFundingAppropriation = new FundingAppropriation()
    },
    async saveFundingAppropriationDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        try {
          let res = await this.saveFundingAppropriation(
            this.selectedFundingAppropriation.removeRelated()
          )
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedFundingAppropriation = new FundingAppropriation()
          this.$refs.form.resetValidation()
        } catch (err) {}
        this.$emit('update:dialog', false)
      }
    },
    cancelEntry() {
      this.selectedFundingAppropriation = new FundingAppropriation()
      this.$refs.form.resetValidation()
      this.$emit('update:dialog', false)
    },
  },
}
</script>
<style></style>
