export default class LuGlobal {
  constructor(objIn = {}) {
    const defaults = {
      //Id is required
      Id: 0,
      //Key is required
      Key: '',
      //Value is required
      Value: '',
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.Id = obj.Id
    this.Key = obj.Key
    this.Value = obj.Value
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    return obj
  }
}
