<template>
  <v-dialog
    persistent
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
    width="850"
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Email"
                v-model="selectedUser.Email"
                :rules="[(v) => !!v || 'required']"
                @blur="getNames"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="First Name"
                v-model="selectedUser.FirstName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Last Name"
                v-model="selectedUser.LastName"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- UserRoles Join Items-->
          <v-row>
            <v-col sm="12">
              <LuRole
                label="Role"
                v-model="selectedUser.UserRoles"
                selfKey="UserId"
                :selfId="selectedUser.Id"
                relatedItemKey="RoleId"
                joinItemName="UserRole"
                :rules=[validateRoles]
              ></LuRole>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-checkbox
                label="Is Active"
                v-model="selectedUser.IsActive"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="saveUserDetails" :disabled="saving" color="primary"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import User from '@classes/User'
//templateTODO: import lookup components you plan to use
import LuRole from '@components/select/LuRole/LuRole-multi-join'
export default {
  components: {
    //templateTodo: define lookup components you are using
    LuRole,
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedUser: new User(),
    modalText: '',
  }),
  created() {},
  computed: {
    ...get('user', ['saving']),
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('user', ['saveUser']),
    async validate() {
      await this.$refs.form.validate()
    },
    editUser(entry) {
      this.selectedUser = new User(entry)
      this.modalText = 'Edit User'
      this.dialog = !this.dialog
    },
    addUser() {
      this.modalText = 'Insert User'
      this.selectedUser = new User()
      this.dialog = !this.dialog
    },
    getNames() {
      if (
        this.selectedUser.Email &&
        this.selectedUser.Email.endsWith('@wyo.gov')
      ) {
        let email = this.selectedUser.Email.replace('@wyo.gov', '')
        let names = email.split('.')
        if (names.length == 2) {
          if (!this.selectedUser.FirstName) {
            let name = names[0].replace(/[0-9]/gi, '')
            this.selectedUser.FirstName =
              name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
          }
        }
        if (!this.selectedUser.LastName) {
          let name = names[1].replace(/[0-9]/gi, '')
          this.selectedUser.LastName =
            name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
        }
      }
    },
    async saveUserDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        try {
          await this.saveUser(this.selectedUser.removeRelated())
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedUser = new User()
          this.$refs.form.resetValidation()
        } catch (err) {}
        this.dialog = false
      }
    },
    validateRoles() {
      if (this.selectedUser.UserRoles.length > 1) {
        var roleIds = this.selectedUser.UserRoles.map(x => x.RoleId)
        if (roleIds.includes(3) && (roleIds.includes(1) || roleIds.includes(2))) {
          return 'User can not have both internal and external roles'
        }
      }
      return true
    },
    cancelEntry() {
      this.dialog = false
      this.selectedUser = new User()
      this.$refs.form.resetValidation()
    },
  },
}
</script>
<style>
</style>