<template>
  <BasePanelWithHeader headerText="Application Document Types">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="filteredApplicationDocumentTypes"
      :searchText="searchText"
      :loading="loading"
    >
      <template v-slot:searchParams>
        <v-row>
          <v-col class="ml-auto" cols="12" sm="auto">
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="auto" class="mt-4 mr-4">
            <BaseTooltipButton
              small
              @click="
                $refs.applicationDocumentTypeForm.addApplicationDocumentType()
              "
              icon="mdi-plus"
              iconColor="white"
              buttonClass="primary"
              >Add Application Document Type
            </BaseTooltipButton>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          @click="
            $refs.applicationDocumentTypeForm.editApplicationDocumentType(item)
          "
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Application Document Type
        </BaseTooltipButton>
      </template>
      <template v-slot:item.IsRequired="{ item }">
        {{ item.IsRequired ? 'Yes' : 'No' }}
      </template>
      <template v-slot:item.IsForHouseholdMember="{ item }">
        {{ item.IsForHouseholdMember ? 'Yes' : 'No' }}
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.applicationDocumentTypeDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete Application Document Type
        </BaseTooltipButton>
      </template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="applicationDocumentTypeDelete"
      :delete="deleteApplicationDocumentType"
      @refresh="loadApplicationDocumentTypes"
    >
      Are you sure you want to delete this application document type?
    </BaseDeleteConfirm>
    <ApplicationDocumentTypeDialogForm
      ref="applicationDocumentTypeForm"
      @refresh="loadApplicationDocumentTypes"
    >
    </ApplicationDocumentTypeDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import ApplicationDocumentTypeDialogForm from '@components/form/add-edit/ApplicationDocumentTypeDialog'
export default {
  components: {
    ApplicationDocumentTypeDialogForm,
  },
  data: () => ({
    searchText: '',
  }),
  created() {
    this.loadApplicationDocumentTypes()
  },
  computed: {
    ...get('applicationDocumentType', ['applicationDocumentTypes', 'loading']),
    filteredApplicationDocumentTypes() {
      var list = this.applicationDocumentTypes.filter((i) => true)
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Document Type',
          align: 'center',
          sortable: true,
          value: 'DocumentType.Description',
          visible: true,
        },
        {
          text: 'Is Required',
          align: 'center',
          sortable: true,
          value: 'IsRequired',
          visible: true,
        },
        {
          text: 'Is For Household Member',
          align: 'center',
          sortable: true,
          value: 'IsForHouseholdMember',
          visible: true,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('applicationDocumentType', [
      'deleteApplicationDocumentType',
      'loadApplicationDocumentTypes',
    ]),
  },
}
</script>
<style></style>
