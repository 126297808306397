<template>
  <BasePanelWithHeader headerText="Document Types">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="filteredLuDocumentTypes"
      :searchText="searchText"
      :loading="loading"
    >
      <template v-slot:searchParams>
        <v-row>
          <v-col cols="12" sm="auto">
            <v-switch v-model="showInactive" label="Show Inactive"></v-switch>
          </v-col>
          <v-col class="ml-auto" cols="12" sm="auto">
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="auto" class="mt-4 mr-4">
            <BaseTooltipButton
              small
              @click="$refs.luDocumentTypeForm.addLuDocumentType()"
              icon="mdi-plus"
              iconColor="white"
              buttonClass="primary"
              >Add Document Type
            </BaseTooltipButton>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luDocumentTypeForm.editLuDocumentType(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Document Type
        </BaseTooltipButton>
      </template>
      <template v-slot:item.InternalOnly="{ item }">
        {{ item.InternalOnly ? 'Yes' : 'No' }}
      </template>
      <template v-slot:item.IsActive="{ item }">
        {{ item.IsActive ? 'Yes' : 'No' }}
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luDocumentTypeDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete Document Type
        </BaseTooltipButton>
      </template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="luDocumentTypeDelete"
      :delete="deleteLuDocumentType"
      @refresh="clearLookupCacheAndReloadLuDocumentTypes"
    >
      Are you sure you want to delete this document type?
    </BaseDeleteConfirm>
    <LuDocumentTypeDialogForm
      ref="luDocumentTypeForm"
      @refresh="clearLookupCacheAndReloadLuDocumentTypes"
    >
    </LuDocumentTypeDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuDocumentTypeDialogForm from '@components/form/add-edit/LuDocumentTypeDialog'
export default {
  components: {
    LuDocumentTypeDialogForm,
  },
  data: () => ({
    searchText: '',
    showInactive: false,
  }),
  created() {
    this.loadLuDocumentTypes()
  },
  computed: {
    ...get('luDocumentType', ['luDocumentTypes', 'loading']),
    filteredLuDocumentTypes() {
      var list = this.luDocumentTypes.filter((i) => true)
      if (!this.showInactive) {
        list = list.filter((i) => i.IsActive)
      }
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Description',
          align: 'center',
          sortable: true,
          value: 'Description',
          visible: true,
        },
        {
          text: 'Internal Only',
          align: 'center',
          sortable: true,
          value: 'InternalOnly',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: this.showInactive,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('luDocumentType', [
      'deleteLuDocumentType',
      'loadLuDocumentTypes',
      'clearLookupCacheAndReloadLuDocumentTypes',
    ]),
  },
}
</script>
<style></style>
