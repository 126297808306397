<template>
  <BasePanelWithHeader headerText="Application Statuses">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="filteredLuApplicationStatuses"
      :searchText="searchText"
      :loading="loading"
    >
      <template v-slot:searchParams>
        <v-row>
          <v-col cols="12" sm="auto">
            <v-switch v-model="showInactive" label="Show Inactive"></v-switch>
          </v-col>
          <v-col class="ml-auto" cols="12" sm="auto">
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="auto" class="mt-4 mr-4">
            <BaseTooltipButton
              small
              @click="$refs.luApplicationStatusForm.addLuApplicationStatus()"
              icon="mdi-plus"
              iconColor="white"
              buttonClass="primary"
              >Add Application Status
            </BaseTooltipButton>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luApplicationStatusForm.editLuApplicationStatus(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Application Status
        </BaseTooltipButton>
      </template>
      <template v-slot:item.IsActive="{ item }">
        {{ item.IsActive ? 'Yes' : 'No' }}
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luApplicationStatusDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete Application Status
        </BaseTooltipButton>
      </template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="luApplicationStatusDelete"
      :delete="deleteLuApplicationStatus"
      @refresh="clearLookupCacheAndReloadLuApplicationStatuses"
    >
      Are you sure you want to delete this application status?
    </BaseDeleteConfirm>
    <LuApplicationStatusDialogForm
      ref="luApplicationStatusForm"
      @refresh="refresh"
    >
    </LuApplicationStatusDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuApplicationStatusDialogForm from '@components/form/add-edit/LuApplicationStatusDialog'
export default {
  components: {
    LuApplicationStatusDialogForm,
  },
  data: () => ({
    searchText: '',
    showInactive: false,
  }),
  created() {
    this.loadLuApplicationStatuses()
  },
  computed: {
    ...get('luApplicationStatus', ['luApplicationStatuses', 'loading']),
    filteredLuApplicationStatuses() {
      var list = this.luApplicationStatuses.filter((i) => true)
      if (!this.showInactive) {
        list = list.filter((i) => i.IsActive)
      }
      list = list.map((i) => {
        return {
          ...i,
          templates: i.StatusTemplates.map((r) => r.Template?.Description).join(
            ', '
          ),
        }
      })
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Status Code',
          align: 'center',
          sortable: true,
          value: 'StatusCode',
          visible: true,
        },
        {
          text: 'Description',
          align: 'center',
          sortable: true,
          value: 'Description',
          visible: true,
        },
        {
          text: 'Templates',
          align: 'center',
          sortable: true,
          value: 'templates',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: this.showInactive,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('luApplicationStatus', [
      'deleteLuApplicationStatus',
      'loadLuApplicationStatuses',
      'clearLookupCacheAndReloadLuApplicationStatuses',
    ]),
    refresh() {
      this.clearLookupCacheAndReloadLuApplicationStatuses().then(() => {
        this.$refs.luApplicationStatusForm.refreshEntry()
      })
    },
  },
}
</script>
<style></style>
