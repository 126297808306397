<template>
  <v-container>
    <v-tabs>
      <v-tab>Users</v-tab>
      <v-tab-item>
        <Users></Users>
      </v-tab-item>
      <!-- <v-tab>States</v-tab>
      <v-tab-item>
        <States></States>
      </v-tab-item> -->
      <v-tab>Cities</v-tab>
      <v-tab-item>
        <Cities></Cities>
      </v-tab-item>
      <v-tab>Counties</v-tab>
      <v-tab-item>
        <Counties></Counties>
      </v-tab-item>
      <v-tab>County Medians</v-tab>
      <v-tab-item>
        <CountyMedians></CountyMedians>
      </v-tab-item>
      <v-tab>Income Thresholds</v-tab>
      <v-tab-item>
        <IncomeThreshold></IncomeThreshold>
      </v-tab-item>
      <v-tab>Application Statuses</v-tab>
      <v-tab-item>
        <ApplicationStatuses></ApplicationStatuses>
      </v-tab-item>
      <v-tab>Note Types</v-tab>
      <v-tab-item>
        <NoteTypes></NoteTypes>
      </v-tab-item>
      <v-tab>Periods</v-tab>
      <v-tab-item>
        <Periods></Periods>
      </v-tab-item>
      <v-tab>Reasons</v-tab>
      <v-tab-item>
        <Reason></Reason>
      </v-tab-item>
      <v-tab>Template Documents</v-tab>
      <v-tab-item>
        <TemplateDocuments></TemplateDocuments>
      </v-tab-item>
      <v-tab>Document Types</v-tab>
      <v-tab-item>
        <DocumentTypes></DocumentTypes>
      </v-tab-item>
      <v-tab>Application Document Types</v-tab>
      <v-tab-item>
        <ApplicationDocumentType></ApplicationDocumentType>
      </v-tab-item>
      <v-tab>Globals</v-tab>
      <v-tab-item>
        <Globals></Globals>
      </v-tab-item>
      <v-tab>Funding Sources</v-tab>
      <v-tab-item>
        <FundingSource></FundingSource>
      </v-tab-item>
      <v-tab>Funding Source Types</v-tab>
      <v-tab-item>
        <FundingSourceType></FundingSourceType>
      </v-tab-item>
      <v-tab>Funding Sponsors</v-tab>
      <v-tab-item>
        <FundingSponsor></FundingSponsor>
      </v-tab-item>
      <v-tab>Funding Appropriations</v-tab>
      <v-tab-item>
        <FundingAppropriation></FundingAppropriation>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>
<script>
import Users from '@components/search/User.vue'
// import States from '@components/admin/LuStateGrid.vue'
import Cities from '@components/admin/LuCityGrid.vue'
import CountyMedians from '@components/admin/CountyMedianGrid.vue'
import IncomeThreshold from '@components/admin/incomeThreshold-grid.vue'
import Counties from '@components/admin/LuCountyGrid.vue'
import ApplicationStatuses from '@components/admin/LuApplicationStatusGrid.vue'
import NoteTypes from '@components/admin/LuNoteTypeGrid.vue'
import Periods from '@components/admin/LuPeriodGrid.vue'
import Reason from '@components/admin/LuReasonGrid.vue'
import TemplateDocuments from '@components/admin/LuTemplateDocumentGrid.vue'
import DocumentTypes from '@components/admin/LuDocumentTypeGrid.vue'
import ApplicationDocumentType from '@components/admin/ApplicationDocumentTypeGrid.vue'
import Globals from '@components/admin/LuGlobalGrid.vue'
import FundingSource from '@components/admin/LuFundingSourceGrid.vue'
import FundingSourceType from '@components/admin/LuFundingSourceTypeGrid.vue'
import FundingSponsor from '@components/admin/LuFundingSponsorGrid.vue'
import FundingAppropriation from '@components/admin/FundingAppropriationGrid.vue'
export default {
  page: {
    title: '',
  },
  data() {
    return {}
  },
  components: {
    Users,
    // States,
    Cities,
    Counties,
    CountyMedians,
    ApplicationStatuses,
    NoteTypes,
    Periods,
    Reason,
    TemplateDocuments,
    DocumentTypes,
    ApplicationDocumentType,
    Globals,
    FundingSource,
    FundingSourceType,
    FundingSponsor,
    FundingAppropriation,
    IncomeThreshold,
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
