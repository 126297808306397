var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"elevation-3"},[_c('v-card-title',{staticClass:"primary text-h5 white--text"},[_vm._v(" "+_vm._s(_vm.headerText)+" ")]),_c('v-card-text',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Income Percentage","type":"number","rules":[
              (v) => parseFloat(v) == parseFloat(v) || 'required',
              (v) =>
                parseFloat(v) <= 3 ||
                'please input percentage as a decimal (i.e. 1.25 would be 125%)',
            ],"hint":"As a decimal (1.25 for 125%)"},model:{value:(_vm.selectedLuIncomeThreshold.IncomePercentage),callback:function ($$v) {_vm.$set(_vm.selectedLuIncomeThreshold, "IncomePercentage", $$v)},expression:"selectedLuIncomeThreshold.IncomePercentage"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Refund Percentage","type":"number","rules":[
              (v) => parseFloat(v) == parseFloat(v) || 'required',
              (v) =>
                parseFloat(v) <= 1 ||
                'please input percentage as a decimal (i.e. .5 would be 50%)',
            ],"hint":"As a decimal (.5 for 50%)"},model:{value:(_vm.selectedLuIncomeThreshold.RefundPercentage),callback:function ($$v) {_vm.$set(_vm.selectedLuIncomeThreshold, "RefundPercentage", $$v)},expression:"selectedLuIncomeThreshold.RefundPercentage"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('BaseDatePickerWithText',{attrs:{"label":"Effective Date mm/dd/yyyy","rules":[(v) => !!v || 'required']},model:{value:(_vm.selectedLuIncomeThreshold.EffectiveDate),callback:function ($$v) {_vm.$set(_vm.selectedLuIncomeThreshold, "EffectiveDate", $$v)},expression:"selectedLuIncomeThreshold.EffectiveDate"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('BaseDatePickerWithText',{attrs:{"label":"Expiration Date mm/dd/yyyy"},model:{value:(_vm.selectedLuIncomeThreshold.ExpirationDate),callback:function ($$v) {_vm.$set(_vm.selectedLuIncomeThreshold, "ExpirationDate", $$v)},expression:"selectedLuIncomeThreshold.ExpirationDate"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":_vm.saving,"color":"primary"},on:{"click":_vm.saveLuIncomeThresholdDetails}},[_vm._v("Save")]),(_vm.inDialog)?_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.cancelEntry}},[_vm._v("Cancel")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }