<template>
  <div>
    <v-row justify="end">
      <v-col cols="12" sm="auto">
        <v-switch v-model="searchShowInactive" label="Show Inactive"></v-switch>
      </v-col>
      <v-col class="ml-auto" cols="12" sm="auto">
        <LuRole dense clearable v-model="searchRole"></LuRole>
      </v-col>
      <v-col sm="12" md="2">
        <v-text-field
          class="pt-2"
          v-model="searchText"
          append-icon="mdi-magnify"
          label="Search"
          clearable
          hide-details
          single-line
          hint="FirstName, LastName, Email, "
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="auto" class="mt-4 mr-4">
        <BaseTooltipButton
          small
          @click="$refs.userForm.addUser()"
          icon="mdi-plus"
          iconColor="white"
          buttonClass="primary"
          >Add User
        </BaseTooltipButton>
      </v-col>
    </v-row>
    <!-- //templateTodo: if you use a custom search dto uncomment this to allow showing of filter params-->
    <!-- <v-row justify="center">
          <v-col sm="12" md="2">
              <h4 @click="toggleFilters" class="pointer primary--text">{{filterText}}
              </h4>
          </v-col>
      </v-row> -->
    <v-row v-if="filters">
      <!-- The Description and Id (item-text and item-value) 
          will need to be adjusted based on the properties in the array/lookup table
          these availableLists should be pulled from lookup tables and potentially
          filtered with a computed as desired (for IsActive status etc)
          These can also be switched to v-autocompletes if desired-->

      <!-- <v-col cols="12" sm="auto">
        <v-switch v-model="searchShowInactive" label="Show Inactive"></v-switch>
        </v-col> -->
      <!-- <v-col cols="12" sm="auto">
              <v-select 
                  v-model="searchIsActive" 
                  :items="['true', 'false']"
                  label="Is Active"
                  chips 
                  deletable-chips 
                  small-chips 
                  clearable 
                  multiple 
                  dense
                  >
              </v-select>
          </v-col> -->
    </v-row>
    <UserDialogForm ref="userForm" @refresh="$emit('refresh')">
    </UserDialogForm>
  </div>
</template>

<script>
import { get, sync, commit, call } from 'vuex-pathify'
import UserDialogForm from '@components/form/add-edit/UserDialog'
import LuRole from '@components/select/LuRole/LuRole-multi-id'
export default {
  name: 'UserSearchParams',
  data: () => ({
    filters: false,
    filterText: '-Hide Filters-',
  }),
  components: {
    UserDialogForm,
    LuRole,
  },
  created() {},
  watch: {},
  computed: {
    ...sync('user', [
      'searchText',
      //templateTODO: uncomment as used in UserSearchParams
      'searchShowInactive',
      'searchRole',
      // 'searchIsActive',
    ]),
    //templateTODO: custom available lookup computed properties should go here
  },
  methods: {
    toggleFilters() {
      this.filters = !this.filters
      if (this.filters) {
        this.filterText = '-Hide Filters-'
      } else {
        this.filterText = '-Show Filters-'
      }
    },
  },
}
</script>

<style lang="scss"></style>
