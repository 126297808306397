<template>
  <v-dialog
    persistent
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
    width="850"
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              <v-text-field
                :disabled="selectedLuGlobal.Id > 0"
                label="Key"
                v-model="selectedLuGlobal.Key"
                :rules="[(v) => !!v || 'required']"
                v-mask="'SXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"
                hint="Must start with a letter, and can contain letters and numbers only"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Value"
                v-model="selectedLuGlobal.Value"
                :rules="[(v) => !!v || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="saveLuGlobalDetails" :disabled="saving" color="primary"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mask } from 'vue-the-mask'
import { get, sync, commit, call } from 'vuex-pathify'
import LuGlobal from '@classes/LuGlobal'
//templateTODO: import lookup components you plan to use
export default {
  components: {
    //templateTodo: define lookup components you are using
  },
  directives: {
    mask,
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedLuGlobal: new LuGlobal(),
    modalText: '',
  }),
  created() {},
  computed: {
    ...get('luGlobal', ['saving']),
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('luGlobal', ['saveLuGlobal']),
    async validate() {
      await this.$refs.form.validate()
    },
    editLuGlobal(entry) {
      this.selectedLuGlobal = new LuGlobal(entry)
      this.modalText = 'Edit Global'
      this.dialog = !this.dialog
    },
    addLuGlobal() {
      this.modalText = 'Insert Global'
      this.selectedLuGlobal = new LuGlobal()
      this.dialog = !this.dialog
    },
    async saveLuGlobalDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        try {
          await this.saveLuGlobal(this.selectedLuGlobal.removeRelated())
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedLuGlobal = new LuGlobal()
          this.$refs.form.resetValidation()
        } catch (err) {}
        this.dialog = false
      }
    },
    cancelEntry() {
      this.dialog = false
      this.selectedLuGlobal = new LuGlobal()
      this.$refs.form.resetValidation()
    },
  },
}
</script>
<style></style>
