<template>
  <BasePanelWithHeader headerText="Funding Appropriations">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="filteredFundingAppropriations"
      :loading="loading"
      search
    >
      <template v-slot:prependParams> </template>
      <template v-slot:appendParams>
        <BaseTooltipButton
          small
          @click="$refs.fundingAppropriationForm.addFundingAppropriation()"
          icon="mdi-plus"
          iconColor="white"
          buttonClass="primary mt-8 ml-2 mb-4"
          >Add Funding Appropriation
        </BaseTooltipButton>
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.fundingAppropriationForm.editFundingAppropriation(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Funding Appropriation
        </BaseTooltipButton>
        <BaseTooltipButton
          small
          @click="refreshFundingAppropriation(item)"
          iconColor="primary"
          icon="mdi-refresh"
          >Recalculate Funding Appropriation
        </BaseTooltipButton>
      </template>
      <template v-slot:item.OriginalAmount="{ item }">{{
        item.OriginalAmount | formatMoney
      }}</template>
      <template v-slot:item.CurrentAmount="{ item }">{{
        item.CurrentAmount | formatMoney
      }}</template>
      <template v-slot:item.CommittedAmount="{ item }">{{
        item.CommittedAmount | formatMoney
      }}</template>
      <template v-slot:item.DisbursedAmount="{ item }">{{
        item.DisbursedAmount | formatMoney
      }}</template>
      <template v-slot:item.AdministrativeFee="{ item }">{{
        item.AdministrativeFee | formatMoney
      }}</template>
      <template v-slot:item.AvailableAmount="{ item }">{{
        item.AvailableAmount | formatMoney
      }}</template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.fundingAppropriationDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete Funding Appropriation
        </BaseTooltipButton>
      </template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="fundingAppropriationDelete"
      :delete="deleteFundingAppropriation"
      @refresh="loadFundingAppropriations"
    >
      Are you sure you want to delete this funding appropriation?
    </BaseDeleteConfirm>
    <FundingAppropriationDialogForm
      ref="fundingAppropriationForm"
      @refresh="loadFundingAppropriations"
    >
    </FundingAppropriationDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import FundingAppropriationDialogForm from '@components/form/add-edit/FundingAppropriationDialog'
export default {
  components: {
    FundingAppropriationDialogForm,
  },
  data: () => ({}),
  created() {
    this.loadFundingAppropriations()
  },
  computed: {
    ...get('fundingAppropriation', ['fundingAppropriations', 'loading']),
    filteredFundingAppropriations() {
      var list = this.fundingAppropriations.filter((i) => true)
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Funding Source Id',
          align: 'center',
          sortable: true,
          value: 'FundingSourceId',
          visible: false,
        },
        {
          text: 'Period',
          align: 'center',
          sortable: true,
          value: 'Period.Period',
          visible: true,
        },
        {
          text: 'Original Amount',
          align: 'center',
          sortable: true,
          value: 'OriginalAmount',
          visible: true,
        },
        {
          text: 'Current Amount',
          align: 'center',
          sortable: true,
          value: 'CurrentAmount',
          visible: true,
        },
        {
          text: 'Committed Amount',
          align: 'center',
          sortable: true,
          value: 'CommittedAmount',
          visible: true,
        },
        {
          text: 'Disbursed Amount',
          align: 'center',
          sortable: true,
          value: 'DisbursedAmount',
          visible: true,
        },
        {
          text: 'Administrative Fee',
          align: 'center',
          sortable: true,
          value: 'AdministrativeFee',
          visible: true,
        },
        {
          text: 'Available Amount',
          align: 'center',
          sortable: true,
          value: 'AvailableAmount',
          visible: true,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('fundingAppropriation', [
      'deleteFundingAppropriation',
      'loadFundingAppropriations',
      'recalculateFundingAppropriation',
    ]),
    async refreshFundingAppropriation(item) {
      await this.recalculateFundingAppropriation(item.Id)
      this.loadFundingAppropriations()
    },
  },
}
</script>
<style></style>
