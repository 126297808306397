<template>
  <v-dialog
    persistent
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
    width="850"
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Period (Tax Year)"
                v-model="selectedLuPeriod.Period"
                type="number"
                :rules="[(v) => parseFloat(v) == parseFloat(v) || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <BaseDatePickerWithText
                label="Application Open Date mm/dd/yyyy"
                v-model="selectedLuPeriod.ApplicationOpenDate"
                :rules="[(v) => !!v || 'required']"
              ></BaseDatePickerWithText>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <BaseDatePickerWithText
                label="Application Deadline mm/dd/yyyy"
                v-model="selectedLuPeriod.ApplicationDeadline"
                :rules="[(v) => !!v || 'required']"
              ></BaseDatePickerWithText>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <BaseDatePickerWithText
                label="Period End Date mm/dd/yyyy"
                v-model="selectedLuPeriod.PeriodEndDate"
                :rules="[(v) => !!v || 'required']"
              ></BaseDatePickerWithText>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <BaseDatePickerWithText
                label="Payment 1 Deadline mm/dd/yyyy"
                v-model="selectedLuPeriod.Payment1Deadline"
                :rules="[(v) => !!v || 'required']"
              ></BaseDatePickerWithText>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <BaseDatePickerWithText
                label="Payment 2 Deadline mm/dd/yyyy"
                v-model="selectedLuPeriod.Payment2Deadline"
                :rules="[(v) => !!v || 'required']"
              ></BaseDatePickerWithText>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <BaseDatePickerWithText
                label="Full Payment Deadline mm/dd/yyyy"
                v-model="selectedLuPeriod.FullPaymentDeadline"
                :rules="[(v) => !!v || 'required']"
              ></BaseDatePickerWithText>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Asset Maximum"
                v-model="selectedLuPeriod.AssetMaximum"
                type="number"
                :rules="[(v) => parseFloat(v) == parseFloat(v) || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Household Maximum"
                v-model="selectedLuPeriod.HouseholdMaximum"
                type="number"
                :rules="[(v) => parseFloat(v) == parseFloat(v) || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Percentage Eligible"
                v-model="selectedLuPeriod.PercentageEligible"
                :rules="[
                  (v) => parseFloat(v) == parseFloat(v) || 'required',
                  (v) =>
                    parseFloat(v) <= 1 ||
                    'please input percentage as a decimal (i.e. .5 would be 50%)',
                ]"
                type="number"
                hint="As a decimal (.5 for 50%)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Budget Fiscal Year"
                v-model="selectedLuPeriod.BudgetFiscalYear"
                :rules="[(v) => parseFloat(v) == parseFloat(v) || 'required']"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- CountyMedians Join Items-->
          <!-- <v-row>
            <v-col sm="12">
              <LuCounty
                label="County"
                v-model="selectedLuPeriod.CountyMedians"
                selfKey="PeriodId"
                :selfId="selectedLuPeriod.Id"
                relatedItemKey="CountyId"
                joinItemName="CountyMedian"
              ></LuCounty>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-card-actions>
          <v-btn @click="saveLuPeriodDetails" :disabled="saving" color="primary"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuPeriod from '@classes/LuPeriod'
//templateTODO: import lookup components you plan to use

export default {
  components: {
    //templateTodo: define lookup components you are using
    //LuCounty
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedLuPeriod: new LuPeriod(),
    modalText: '',
  }),
  created() {},
  computed: {
    ...get('luPeriod', ['saving']),
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('luPeriod', ['saveLuPeriod']),
    async validate() {
      await this.$refs.form.validate()
    },
    editLuPeriod(entry) {
      this.selectedLuPeriod = new LuPeriod(entry)
      this.modalText = 'Edit Period'
      this.dialog = !this.dialog
    },
    addLuPeriod() {
      this.modalText = 'Insert Period'
      this.selectedLuPeriod = new LuPeriod()
      this.dialog = !this.dialog
    },
    async saveLuPeriodDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        try {
          await this.saveLuPeriod(this.selectedLuPeriod.removeRelated())
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedLuPeriod = new LuPeriod()
          this.$refs.form.resetValidation()
        } catch (err) {}
        this.dialog = false
      }
    },
    cancelEntry() {
      this.dialog = false
      this.selectedLuPeriod = new LuPeriod()
      this.$refs.form.resetValidation()
    },
  },
}
</script>
<style></style>
