<template>
  <BasePanelWithHeader headerText="Template Documents">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="filteredLuTemplateDocuments"
      :searchText="searchText"
      :loading="loading"
    >
      <template v-slot:searchParams>
        <v-row>
          <v-col class="ml-auto" cols="12" sm="auto">
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="auto" class="mt-4 mr-4">
            <BaseTooltipButton
              small
              @click="$refs.luTemplateDocumentForm.addLuTemplateDocument()"
              icon="mdi-plus"
              iconColor="white"
              buttonClass="primary"
              >Add Template Document
            </BaseTooltipButton>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luTemplateDocumentForm.editLuTemplateDocument(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit Template Document
        </BaseTooltipButton>
      </template>
      <template v-slot:item.download="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              color="primary"
              v-on="on"
              icon
              @click="$refs.luTemplateDocumentForm.downloadDocument(item)"
            >
              <v-icon class="clickable" color="primary">mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Download Document</span>
        </v-tooltip>
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luTemplateDocumentDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete Template Document
        </BaseTooltipButton>
      </template>
    </BaseSimpleDataTable>
    <template v-slot:item.download="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            small
            color="primary"
            v-on="on"
            icon
            @click="$refs.luTemplateDocumentForm.downloadDocument(item)"
          >
            <v-icon class="clickable" color="primary">mdi-download</v-icon>
          </v-btn>
        </template>
        <span>Download Document</span>
      </v-tooltip>
    </template>
    <BaseDeleteConfirm
      ref="luTemplateDocumentDelete"
      :delete="deleteLuTemplateDocument"
      @refresh="clearLookupCacheAndReloadLuTemplateDocuments"
    >
      Are you sure you want to delete this template document?
    </BaseDeleteConfirm>
    <LuTemplateDocumentDialogForm
      ref="luTemplateDocumentForm"
      @refresh="clearLookupCacheAndReloadLuTemplateDocuments"
    >
    </LuTemplateDocumentDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuTemplateDocumentDialogForm from '@components/form/add-edit/LuTemplateDocumentDialog'
export default {
  components: {
    LuTemplateDocumentDialogForm,
  },
  data: () => ({
    searchText: '',
  }),
  created() {
    this.loadLuTemplateDocuments()
  },
  computed: {
    ...get('luTemplateDocument', ['luTemplateDocuments', 'loading']),
    filteredLuTemplateDocuments() {
      var list = this.luTemplateDocuments.filter((i) => true)
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: '',
          align: 'center',
          value: 'download',
          visible: true,
          sortable: false,
        },
        {
          text: 'Description',
          align: 'center',
          sortable: true,
          value: 'Description',
          visible: true,
        },
        {
          text: 'Document Id',
          align: 'center',
          sortable: true,
          value: 'DocumentId',
          visible: false,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('luTemplateDocument', [
      'deleteLuTemplateDocument',
      'loadLuTemplateDocuments',
      'clearLookupCacheAndReloadLuTemplateDocuments',
    ]),
  },
}
</script>
<style></style>
