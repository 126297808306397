<template>
  <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
    <v-card class="elevation-3">
      <v-card-title class="primary text-h5 white--text">
        {{ headerText }}
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Description"
              v-model="selectedLuFundingSponsor.Description"
              :rules="[(v) => !!v || 'required']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-checkbox
              label="Is Active"
              v-model="selectedLuFundingSponsor.IsActive"
              :rules="[(v) => v === true || v === false || 'required']"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="saveLuFundingSponsorDetails"
          :disabled="saving"
          color="primary"
          >Save</v-btn
        >
        <v-btn v-if="inDialog" outlined color="primary" @click="cancelEntry"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuFundingSponsor from '@classes/LuFundingSponsor'
//templateTODO: import lookup components you plan to use
export default {
  components: {
    //templateTodo: define lookup components you are using
  },
  props: {
    dialog: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    valid: true,
    selectedLuFundingSponsor: new LuFundingSponsor(),
    headerText: 'LuFundingSponsor',
  }),
  created() {},
  computed: {
    ...get('luFundingSponsor', ['saving']),
    inDialog() {
      if (this.dialog === false || this.dialog === true) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    ...call('luFundingSponsor', ['saveLuFundingSponsor']),
    async validate() {
      await this.$refs.form.validate()
    },
    editLuFundingSponsor(entry) {
      this.selectedLuFundingSponsor = new LuFundingSponsor(entry)
      this.headerText = 'Edit Funding Sponsor'
    },
    addLuFundingSponsor() {
      this.headerText = 'Insert Funding Sponsor'
      this.selectedLuFundingSponsor = new LuFundingSponsor()
    },
    async saveLuFundingSponsorDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        try {
          let res = await this.saveLuFundingSponsor(
            this.selectedLuFundingSponsor.removeRelated()
          )
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedLuFundingSponsor = new LuFundingSponsor()
          this.$refs.form.resetValidation()
        } catch (err) {}
        this.$emit('update:dialog', false)
      }
    },
    cancelEntry() {
      this.selectedLuFundingSponsor = new LuFundingSponsor()
      this.$refs.form.resetValidation()
      this.$emit('update:dialog', false)
    },
  },
}
</script>
<style></style>
