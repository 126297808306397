<template>
  <v-dialog persistent v-model="dialog" scrollable @keydown.esc="dialog = false" width="850">
    <LuFundingSponsorForm 
      ref="luFundingSponsorForm" 
      :dialog.sync="dialog" 
      @refresh="$emit('refresh')" 
      @itemAdded="$emit('itemAdded', $event)">
    </LuFundingSponsorForm>
  </v-dialog>
</template>
<script>
  import LuFundingSponsorForm from '@components/form/add-edit/LuFundingSponsorForm'
export default {
  components: {
    LuFundingSponsorForm,
  },
  data: () => ({
    dialog: false,
  }),
  created() {},
  computed: {
  },
  methods: {
    editLuFundingSponsor(entry) {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.luFundingSponsorForm.editLuFundingSponsor(entry)
      })
    },
    addLuFundingSponsor() {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.luFundingSponsorForm.addLuFundingSponsor()
      })
    },
  },
  } 
  
</script>
<style>

</style>