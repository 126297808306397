<template>
  <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
    <v-card class="elevation-3">
      <v-card-title class="primary text-h5 white--text">
        {{ headerText }}
      </v-card-title>
      <v-card-text class="pa-4">
        <!-- <v-row>
            <v-col cols="12">
              <LuApplicationStatus
                label="Current Status"
                v-model="selectedNextAvailableStatus.CurrentStatusId"
                  :rules="[v => !!v || 'required']"
              ></LuApplicationStatus>
            </v-col>
          </v-row> -->
        <v-row>
          <v-col cols="12">
            <LuApplicationStatus
              label="Next Status"
              v-model="selectedNextAvailableStatus.NextStatusId"
              :rules="[(v) => !!v || 'required']"
            ></LuApplicationStatus>
          </v-col>
        </v-row>
        <!-- AvailableStatusRoles Join Items-->
        <v-row>
          <v-col cols="12">
            <LuRole
              label="Role"
              v-model="selectedNextAvailableStatus.AvailableStatusRoles"
              selfKey="AvailableStatusId"
              :selfId="selectedNextAvailableStatus.Id"
              relatedItemKey="RoleId"
              joinItemName="AvailableStatusRole"
            ></LuRole>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="saveNextAvailableStatusDetails"
          :disabled="saving"
          color="primary"
          >Save</v-btn
        >
        <v-btn v-if="inDialog" outlined color="primary" @click="cancelEntry"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import NextAvailableStatus from '@classes/NextAvailableStatus'
//templateTODO: import lookup components you plan to use

import LuApplicationStatus from '@components/select/LuApplicationStatus/LuApplicationStatus-single'
import LuRole from '@components/select/LuRole/LuRole-multi-join'
export default {
  components: {
    //templateTodo: define lookup components you are using
    //LuApplicationStatus,
    LuApplicationStatus,
    LuRole,
  },
  props: {
    dialog: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    valid: true,
    selectedNextAvailableStatus: new NextAvailableStatus(),
    headerText: 'NextAvailableStatus',
  }),
  created() {},
  computed: {
    ...get('nextAvailableStatus', ['saving']),
    inDialog() {
      if (this.dialog === false || this.dialog === true) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    ...call('nextAvailableStatus', ['saveNextAvailableStatus']),
    async validate() {
      await this.$refs.form.validate()
    },
    editNextAvailableStatus(entry) {
      this.selectedNextAvailableStatus = new NextAvailableStatus(entry)
      this.headerText = 'Edit Next Available Status'
    },
    addNextAvailableStatus(currentStatusId) {
      this.headerText = 'Insert Next Available Status'
      this.selectedNextAvailableStatus = new NextAvailableStatus({
        CurrentStatusId: currentStatusId,
      })
    },
    async saveNextAvailableStatusDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        try {
          let res = await this.saveNextAvailableStatus(
            this.selectedNextAvailableStatus.removeRelated()
          )
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedNextAvailableStatus = new NextAvailableStatus()
          this.$refs.form.resetValidation()
        } catch (err) {}
        this.$emit('update:dialog', false)
      }
    },
    cancelEntry() {
      this.selectedNextAvailableStatus = new NextAvailableStatus()
      this.$refs.form.resetValidation()
      this.$emit('update:dialog', false)
    },
  },
}
</script>
<style></style>
