<template>
  <v-dialog
    persistent
    v-model="dialog"
    scrollable
    @keydown.esc="dialog = false"
    width="850"
  >
    <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
      <v-card class="elevation-3">
        <v-card-title class="primary text-h5 white--text">
          {{ modalText }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Status Code"
                v-model="selectedLuApplicationStatus.StatusCode"
                :rules="[(v) => !!v || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-text-field
                label="Description"
                v-model="selectedLuApplicationStatus.Description"
                :rules="[(v) => !!v || 'required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <LuTemplateDocument
                label="Template"
                v-model="selectedLuApplicationStatus.StatusTemplates"
                selfKey="StatusId"
                :selfId="selectedLuApplicationStatus.Id"
                relatedItemKey="TemplateId"
                joinItemName="StatusTemplate"
              ></LuTemplateDocument>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-checkbox
                label="Is Active"
                v-model="selectedLuApplicationStatus.IsActive"
                :rules="[(v) => v === true || v === false || 'required']"
              ></v-checkbox>
            </v-col>
          </v-row>
          <NextAvailableStatusGrid
            v-if="selectedLuApplicationStatus.Id > 0"
            :statusId="selectedLuApplicationStatus.Id"
            :items="selectedStatus.NextAvailableStatuses"
            @refresh="$emit('refresh')"
            @itemAdded="nextAvailableStatusAdded"
          >
            ></NextAvailableStatusGrid
          >
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="saveLuApplicationStatusDetails"
            :disabled="saving"
            color="primary"
            >Save</v-btn
          >
          <v-btn outlined color="primary" @click="cancelEntry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuApplicationStatus from '@classes/LuApplicationStatus'
import NextAvailableStatusGrid from '@components/admin/NextAvailableStatusGrid'
import LuTemplateDocument from '@components/select/LuTemplateDocument/LuTemplateDocument-multi-join'
//templateTODO: import lookup components you plan to use
export default {
  components: {
    //templateTodo: define lookup components you are using
    NextAvailableStatusGrid,
    LuTemplateDocument,
  },
  data: () => ({
    dialog: false,
    valid: true,
    selectedLuApplicationStatus: new LuApplicationStatus(),
    selectedStatus: {},
    modalText: '',
  }),
  created() {},
  computed: {
    ...get('luApplicationStatus', ['saving', 'luApplicationStatuses']),
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('luApplicationStatus', ['saveLuApplicationStatus']),
    async validate() {
      await this.$refs.form.validate()
    },
    refreshEntry() {
      console.devlog('refresh entry')
      let match = this.luApplicationStatuses.find(
        (x) => x.Id == this.selectedLuApplicationStatus.Id
      )
      if (match) {
        this.selectedStatus = match
      }
      console.devlog(match)
    },
    editLuApplicationStatus(entry) {
      this.selectedLuApplicationStatus = new LuApplicationStatus(entry)
      this.selectedStatus = entry
      this.modalText = 'Edit Application Status'
      this.dialog = !this.dialog
    },
    addLuApplicationStatus() {
      this.modalText = 'Insert Application Status'
      this.selectedLuApplicationStatus = new LuApplicationStatus()
      this.selectedStatus = {}
      this.dialog = !this.dialog
    },
    nextAvailableStatusAdded(entry) {
      console.devlog('entry', entry)
    },
    async saveLuApplicationStatusDetails() {
      await this.validate()
      if (this.valid) {
        //templateTODO: if you want to save related item arrays you will need to add a getSaveData() function to the javascript class and call that instead
        try {
          await this.saveLuApplicationStatus(
            this.selectedLuApplicationStatus.getSaveData()
          )
          this.$emit('refresh')
          this.$emit('itemAdded', res.data)
          this.selectedLuApplicationStatus = new LuApplicationStatus()
          this.selectedStatus = {}
          this.$refs.form.resetValidation()
        } catch (err) {}
        this.dialog = false
      }
    },
    cancelEntry() {
      this.dialog = false
      this.selectedLuApplicationStatus = new LuApplicationStatus()
      this.selectedStatus = {}
      this.$refs.form.resetValidation()
    },
  },
}
</script>
<style></style>
