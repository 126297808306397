<template>
  <BasePanelWithHeader headerText="Cities">
    <BaseSimpleDataTable
      :headers="filteredHeaders"
      :items="filteredLuCities"
      :searchText="searchText"
      class="pt-4"
      :loading="loading"
      dense
    >
      <template v-slot:searchParams>
        <v-row>
          <v-col cols="12" sm="auto">
            <v-switch v-model="showInactive" label="Show Inactive"></v-switch>
          </v-col>
          <v-col class="ml-auto" cols="12" sm="auto">
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="auto" class="mt-4 mr-4">
            <BaseTooltipButton
              small
              @click="$refs.luCityForm.addLuCity()"
              icon="mdi-plus"
              iconColor="white"
              buttonClass="primary"
              >Add City
            </BaseTooltipButton>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.edit="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luCityForm.editLuCity(item)"
          iconColor="primary"
          icon="mdi-pencil"
          >Edit City
        </BaseTooltipButton>
      </template>
      <template v-slot:item.IsActive="{ item }">
        {{ item.IsActive ? 'Yes' : 'No' }}
      </template>
      <template v-slot:item.delete="{ item }">
        <BaseTooltipButton
          small
          @click="$refs.luCityDelete.deleteConfirm(item)"
          iconColor="primary"
          icon="mdi-delete"
          >Delete City
        </BaseTooltipButton>
      </template>
    </BaseSimpleDataTable>
    <BaseDeleteConfirm
      ref="luCityDelete"
      :delete="deleteLuCity"
      @refresh="clearLookupCacheAndReloadLuCities"
    >
      Are you sure you want to delete this city?
    </BaseDeleteConfirm>
    <LuCityDialogForm
      ref="luCityForm"
      @refresh="clearLookupCacheAndReloadLuCities"
    >
    </LuCityDialogForm>
  </BasePanelWithHeader>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LuCityDialogForm from '@components/form/add-edit/LuCityDialog'
export default {
  components: {
    LuCityDialogForm,
  },
  data: () => ({
    searchText: '',
    showInactive: false,
  }),
  created() {
    this.loadLuCities()
  },
  computed: {
    ...get('luCity', ['luCities', 'loading']),
    filteredLuCities() {
      var list = this.luCities.filter((i) => true)
      if (!this.showInactive) {
        list = list.filter((i) => i.IsActive)
      }
      list = list.map((item) => {
        let name = item.Counties.map((i) => i.Name)
        return {
          ...item,
          counties: name.join(', '),
        }
      })
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'Id',
          visible: false,
        },
        {
          text: 'Name',
          align: 'center',
          sortable: true,
          value: 'Name',
          visible: true,
        },
        {
          text: 'Counties',
          align: 'center',
          sortable: true,
          value: 'counties',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: this.showInactive,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    ...call('luCity', [
      'deleteLuCity',
      'loadLuCities',
      'clearLookupCacheAndReloadLuCities',
    ]),
  },
}
</script>
<style></style>
