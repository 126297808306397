<template>
  <v-dialog persistent v-model="dialog" scrollable @keydown.esc="dialog = false" width="850">
    <LuFundingSourceTypeForm 
      ref="luFundingSourceTypeForm" 
      :dialog.sync="dialog" 
      @refresh="$emit('refresh')" 
      @itemAdded="$emit('itemAdded', $event)">
    </LuFundingSourceTypeForm>
  </v-dialog>
</template>
<script>
  import LuFundingSourceTypeForm from '@components/form/add-edit/LuFundingSourceTypeForm'
export default {
  components: {
    LuFundingSourceTypeForm,
  },
  data: () => ({
    dialog: false,
  }),
  created() {},
  computed: {
  },
  methods: {
    editLuFundingSourceType(entry) {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.luFundingSourceTypeForm.editLuFundingSourceType(entry)
      })
    },
    addLuFundingSourceType() {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.luFundingSourceTypeForm.addLuFundingSourceType()
      })
    },
  },
  } 
  
</script>
<style>

</style>