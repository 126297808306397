<template>
  <v-dialog persistent v-model="dialog" scrollable @keydown.esc="dialog = false" width="850">
    <LuFundingSourceForm 
      ref="luFundingSourceForm" 
      :dialog.sync="dialog" 
      @refresh="$emit('refresh')" 
      @itemAdded="$emit('itemAdded', $event)">
    </LuFundingSourceForm>
  </v-dialog>
</template>
<script>
  import LuFundingSourceForm from '@components/form/add-edit/LuFundingSourceForm'
export default {
  components: {
    LuFundingSourceForm,
  },
  data: () => ({
    dialog: false,
  }),
  created() {},
  computed: {
  },
  methods: {
    editLuFundingSource(entry) {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.luFundingSourceForm.editLuFundingSource(entry)
      })
    },
    addLuFundingSource() {
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.luFundingSourceForm.addLuFundingSource()
      })
    },
  },
  } 
  
</script>
<style>

</style>